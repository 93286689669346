import produce from "immer";
import { 
    SNACKBAR_ERROR, 
    SNACKBAR_HIDE, 
    SNACKBAR_SUCCESS 
} from "../types";


const initialState = {
    message: "",
    type: "",
    show: false,
};

const snackbarReducer = produce((draft, action) => {
    switch (action.type) {
        case SNACKBAR_ERROR:
            draft.type = action.type;
            draft.show = true;
            draft.message = action.message;
            return;
        case SNACKBAR_SUCCESS:
            draft.type = action.type;
            draft.show = true;
            draft.message = action.message;
            return;
        case SNACKBAR_HIDE:
            draft.show = false;
            draft.message = "";
            return;
        default:
            return;
    }
}, initialState);


export default snackbarReducer;