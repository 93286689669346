import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Locize from "i18next-locize-backend";
import i18next from "i18next";
import * as serviceWorker from "./serviceWorker";
import { initReactI18next } from "react-i18next";
import { i18nConfig } from "./shared/i18nConfig";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// Configuration before launching React
library.add(fas);
i18next
    .use(Locize)
    .use(initReactI18next)
    .init(i18nConfig, (error) => {
        if (error) {
            return console.error("Error initializing i18next", error);
        }
    });

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
