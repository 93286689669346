import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Chapter from "./Chapter";

import "./style.scss";

const Report = ({
    book,
    review,
    finished
}) => {

    if (!review.reviewData ||
        !review.progressState
    ) {
        return null;
    }

    const { chapters: chapterReview } = review.reviewData;
    const { chapters: chapterProgress } = review.progressState;
    const { chapters } = book;

    const chapterKeys = Object.keys(chapterProgress);

    const renderChapters = () => {
        return chapterKeys.map((chapterId) => {
            const chapter = chapters[chapterId];
            const answers = chapterProgress[chapterId].items;
            const review = chapterReview[chapterId] || {};
            return (
                <Chapter
                    key={chapterId}
                    chapter={chapter}
                    answers={answers}
                    review={review}
                    chapterProgress={chapterProgress}
                />
            );

        });
    };

    const reportClasses = classNames(
        "review-report",
        finished ? "finished" : ""
    );

    return (
        <div className={reportClasses}>
            {renderChapters()}
        </div>
    );
};

Report.propTypes = {
    book: PropTypes.object,
    review: PropTypes.object,
    archived: PropTypes.bool,
    finished: PropTypes.bool
};

export default Report;