import React from "react";
import PropTypes from "prop-types";
// import "./style.scss";

export default function RadioButton(props) {

    const { selected, label, onHandleSelected, value } = props;
    return (
        <label
            onClick={() => { onHandleSelected(value); }}
            className={`radio-button ${selected ? "selected" : ""}`}
        >
            <span className={`checkmark ${selected ? "selected" : ""}`} />
            {label}
        </label>
    );
}

RadioButton.propTypes = {
    selected: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onHandleSelected: PropTypes.func.isRequired,
    value: PropTypes.number,
};