import moment from "moment";

export default function nextAuditDate(audit) {
    const {
        recoveryPeriod,
        lastInspectionDate,
        inspectionDeadline
    } = audit;

    if (recoveryPeriod) {
        if (lastInspectionDate) {
            if (inspectionDeadline > lastInspectionDate) {
                return moment(inspectionDeadline)
                    .add(recoveryPeriod.time, recoveryPeriod.times);
            } else {
                return moment(lastInspectionDate);

            }

        } else {
            return moment(inspectionDeadline)
                .add(recoveryPeriod.time, recoveryPeriod.times);
        }
    } else {
        return inspectionDeadline;
    }
}