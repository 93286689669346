

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon, { ICONS } from "../../Icon";

import "./style.scss";

const ButtonBack = ({
    label,
    onClick,
    testId,
    dark,
    spacedLeft,
    spacedRight
}) => {

    const buttonClasses = classNames(
        "button-back",
        dark ? "dark" : "light",
        spacedLeft ? "spaced-left" : "",
        spacedRight ? "spaced-right" : "",
    );
    
    return (
        <span
            data-testid={testId}
            onClick={onClick}
            className={buttonClasses}>
            <Icon spacedRight name={ICONS.BACK} />
            <span>{label}</span>
        </span>
    );
};

ButtonBack.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    testId: PropTypes.string,
    dark: PropTypes.bool,
    spacedLeft: PropTypes.bool,
    spacedRight: PropTypes.bool,
};

export default ButtonBack;