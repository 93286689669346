import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import inspectionHistoryLoad from "redux/actions/audit/inspectionHistoryLoad";
import HistoryItem from "components/HistoryItem";

const TabHistory = ({ inspection }) => {

    const dispatch = useDispatch();
    const { inspections, loading } = useSelector(state => state.inspectionHistory);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(inspectionHistoryLoad(inspection.subscriptionId));
    }, []);

    return (
        <div className="inspection-history">
            {!loading ?
                inspections.length > 0 ?
                    inspections.map((oldInspection) => {
                        return (
                            <HistoryItem
                                inspection={oldInspection}
                                contacts={inspection.contacts}
                                key={inspection.id}
                            />
                        );
                    }) :
                    <span>{t("inspection.history.noResults")}</span>
                :
                <div />
            }
        </div>
    );
};

TabHistory.propTypes = {
    inspection: PropTypes.object.isRequired
};

export default TabHistory;