import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { DOSSIER_ACTIVITY_LOADED } from "../../types";

const bookDossierActivity = loader("graphql/queries/bookDossierActivity.graphql");

export function dossierActivityLoad(inspectionId, draftDossierId, dossierId) {
    return async (dispatch, getState) => {

        const { id: organisationId } = getState().organisation.current;
        const { data } = await client.query({
            query: bookDossierActivity,
            variables: { 
                dossierId, 
                inspectionId,
                organisationId
            }
        });

        const { bookDossierActivity: activity } = data;

        dispatch({ 
            type: DOSSIER_ACTIVITY_LOADED, 
            activity, 
            draftDossierId 
        });
    };
}
