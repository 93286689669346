import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import auth0 from "auth0-js";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

import AuthSidebar from "./AuthSidebar";
import AuthRegister from "./AuthRegister";
import AuthLogin from "./AuthLogin";
import AuthPasswordReset from "./AuthPasswordReset";
import getLoginOptions from "shared/helpers/getLoginOptions";
import Loading from "shared/components/Loading";
import isDevelopment from "shared/helpers/isDevelopment";

import "./style.scss";

const Authentication = ({ defaultRoute }) => {

    const dispatch = useDispatch();
    const [passwordReset, setPasswordReset] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true);

    // not using the router due to strange bug leading to 401s
    // so now we manually check the slug to end with 'register'
    const [isRegistration, setRegistration] =
        useState(window.location.pathname.endsWith("register"));

    const checkAuth = async () => {
        const cookies = new Cookies();

        if (window.location.hash?.length > 0) {
            const webAuth = new auth0.WebAuth(getLoginOptions());
            webAuth.parseHash({ hash: window.location.hash }, async (err, authResult) => {
                if (err) {
                    return console.log(err);
                }
                const cookieOptions = { path: "/", domain: !isDevelopment() ? ".okcomply.com" : undefined };
                const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
                cookies.set("access_token", authResult.accessToken, cookieOptions);
                cookies.set("id_token", authResult.idToken, cookieOptions);
                cookies.set("expires_at", expiresAt, cookieOptions);

                const redirect = localStorage.getItem("redirect");
                
                window.location = redirect?.length > 0 ? `/${redirect}` : defaultRoute;
            });
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAuth();
        let root = document.getElementsByTagName("html")[0];
        root.className = "";
        return () => {
            let root = document.getElementsByTagName("html")[0];
            root.className = "has-navbar-fixed-top";
        };
    }, [dispatch]);


    const registerUserForm = () => {
        return (
            <AuthRegister
                email={email}
                setEmail={setEmail}
                setRegistration={setRegistration}
            />
        );
    };

    const renderPasswordResetForm = () => {
        return (
            <AuthPasswordReset
                setEmail={setEmail}
                email={email}
                setPasswordReset={setPasswordReset}
            />
        );
    };

    const loginUserForm = () => {
        return (
            <AuthLogin
                setEmail={setEmail}
                email={email}
                setPasswordReset={setPasswordReset}
                setRegistration={setRegistration}
            />
        );
    };

    const formLanding = () => {
        if (passwordReset) {
            return renderPasswordResetForm();
        }
        if (isRegistration) {
            return registerUserForm();
        } else {
            return loginUserForm();
        }
    };

    return (
        <div className="container-no-nav">
            <div className="onboarding-landing">
                <AuthSidebar />
                <div className="form-landing">
                    {!loading ? formLanding() : <Loading />}
                </div>
            </div>
        </div>
    );
};

Authentication.propTypes = {
    defaultRoute: PropTypes.string.isRequired,
};

export default Authentication;
