export const IS_DEV = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");

export const ENVIRONMENT = IS_DEV ? "development" :
    window.location.href.includes("acceptance") ?
        "acceptance" :
        "production";

export const ENVIRONMENT_URLS = {
    "development": "http://localhost:1337",
    "acceptance": "https://acceptance.okcomply.com",
    "production": "https://my.okcomply.com"
};

export const ENVIRONMENT_URL = ENVIRONMENT_URLS[ENVIRONMENT];
