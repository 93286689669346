import produce from "immer";

import {
    ORGANISATION_LOADED,
    ORGANISATIONS_LOADED
} from "../types";

const initialState = {
    organisations: [],
    current: null,
    loading: true,
};

const organisationReducer = produce((draft, action) => {
    switch (action.type) {
        case ORGANISATION_LOADED:
            draft.current = action.organisation;
            draft.loading = false;
            break;
        case ORGANISATIONS_LOADED:
            draft.organisations = action.organisations;
            draft.loading = false;
            break;
        default:
            break;
    }
}, initialState);

export default organisationReducer;