import React from "react";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Result from "./Result";
import Questions from "./Questions";
import ButtonFlow from "components/ButtonFlow";
import { REVIEW_STATE } from "constants/audit";

import "./style.scss";

const Content = () => {

    const { id: chapterId, inspectionId } = useParams();
    const [t] = useTranslation();
    const history = useHistory();
    const auditReducer = useSelector(state => state.audit);
    const review = useSelector(state => state.review);

    const { inspections } = auditReducer;

    if (!(inspectionId in inspections) || review.loading) {
        return null;
    }

    const inspection = inspections[inspectionId];

    const { book } = inspection;
    const { chapters } = book;

    const { chapters: chapterReviews } = review.reviewData;
    const { chapters: chapterProgress } = review.progressState;

    const currentChapter = chapters[chapterId];
    const questions = currentChapter.items;
    const answers = chapterProgress[chapterId].items;
    const status = chapterProgress[chapterId].status;
    const chapterReview = chapterReviews[chapterId] || {};

    const nextChapter = () => {
        const chapterIds = Object.keys(chapterProgress);
        let navigated = false;

        chapterIds.forEach((id, index) => {
            if (id === chapterId) {
                if (index < chapterIds.length - 1) {
                    const newChapter = chapterIds[index + 1];
                    navigated = true;
                    history.push(`/inspection/${inspectionId}/review/${newChapter}`);

                }
            }
        });

        if (!navigated) {
            history.push(`/inspection/${inspectionId}/resolution/overview`);
        }

    };

    const previousChapter = () => {
        const chapterIds = Object.keys(chapterProgress);
        chapterIds.forEach((id, index) => {
            if (id === chapterId) {
                if (index > 0) {
                    const newChapter = chapterIds[index - 1];
                    history.push(`/inspection/${inspectionId}/review/${newChapter}`);
                }
            }
        });
    };



    const draftDossierIds = Object.keys(answers)
        .filter(key => answers[key].draft)
        .map(filteredKey => {
            return answers[filteredKey].draft.id;
        });


    return (
        <div className="audit-review">
            <div className="container-header">
                <ButtonFlow onClick={previousChapter} />
                <h1>{currentChapter.label}</h1>
                <ButtonFlow
                    isNext
                    onClick={nextChapter}
                />
            </div>
            <div className="spacer" />
            <div className="group">
                <h3>{t("audit.questions.title")}</h3>
                {status !== REVIEW_STATE.FINISHED &&
                    <>
                        <div className="spacer" />
                        <span className="container-warning">
                            {t("audit.chapter.notComplete")}
                        </span>
                    </>
                }
                <Questions
                    chapterLabel={currentChapter.label}
                    questions={questions}
                    answers={answers}
                    review={chapterReview}
                />
            </div>
            {(draftDossierIds.length > 0) && // Only display when filled
                <div className="group">
                    <h3>{t("audit.resulted.title")}</h3>
                    <div className="spacer" />
                    <Result draftDossiers={draftDossierIds} />
                </div>
            }
        </div>
    );
};

export default Content;
