import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { INSPECTION_ARCHIVED } from "redux/types";

const archiveBookInspection = loader("graphql/mutations/archiveBookInspection.graphql");

export default function inspectionArchive(inspectionId) {
    return async (dispatch) => {
        try {
            await client.mutate({ mutation: archiveBookInspection, variables: { inspectionId } });
            dispatch(snackbarSuccess("audit.inspection.archived"));
            dispatch({
                type: INSPECTION_ARCHIVED,
                inspectionId
            });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error)));
        }
    };
}
