import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_LOADING, REVIEW_FINISHED } from "redux/types";
import createErrorMessage from "helpers/createErrorMessage";
import { snackbarError } from "shared/redux/actions/Snackbar";

const approveBookInspectionReview = loader("graphql/mutations/approveBookInspectionReview.graphql");

export default function reviewPositiveResolution(
    inspectionId,
    assessmentRemark,
) {
    return async (dispatch) => {
        try {

            dispatch({ type: REVIEW_LOADING });

            const { data } = await client.mutate({
                mutation: approveBookInspectionReview,
                variables: {
                    inspectionId,
                    assessmentRemark,
                }
            });

            const resolution = data.approveBookInspectionReview;

            dispatch({
                type: REVIEW_FINISHED,
                inspectionId,
                positive: true,
                resolution
            });

        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "review.finish.failed")));
        }
    };
}
