import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_COMMENT_DELETED } from "redux/types";

const deleteReviewComment = loader("graphql/mutations/deleteReviewComment.graphql");

export function reviewCommentDelete(
    inspectionId,
    chapterId,
    questionId,
    key
) {
    return async (dispatch) => {

        await client.mutate({
            mutation: deleteReviewComment,
            variables: {
                inspectionId,
                chapter: chapterId,
                item: questionId,
                key
            }
        });

        dispatch({
            type: REVIEW_COMMENT_DELETED,
            chapterId,
            questionId,
            key,
        });

    };
}
