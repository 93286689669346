import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Button from "shared/components/buttons/Button";
import InputDate from "shared/components/inputs/InputDate";
import InputPhase from "components/InputPhase";
import usersToSelectOptions from "helpers/usersToSelectOptions";
import InputSelect from "shared/components/inputs/InputSelect";
import inspectionUpdate from "redux/actions/audit/inspectionUpdate";
import inspectionArchive from "redux/actions/audit/inspectionArchive";
import inspectionReopen from"redux/actions/audit/inspectionReopen";

const TabEdit = ({ inspection, onClose }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const organisation = useSelector(state => state.organisation.current);
    const { users } = useSelector(state => state.users);

    const [deadline, setDeadline] = useState(inspection.inspectionDeadline);
    const [time, setTime] = useState(inspection.inspectEvery.time);
    const [times, setTimes] = useState(parseInt(inspection.inspectEvery.times));
    const [auditor, setAuditor] = useState(inspection.auditors[0].id);
    const userOptions = usersToSelectOptions(users);

    const stop = async () => {
        await dispatch(inspectionArchive(inspection.id));
        onClose();
    }

    const reopen = async ()=> {
        await dispatch(inspectionReopen(inspection.id));
        onClose();
    }

    const update = async () => {
        const updateData = {
            inspectionId: inspection.id,
            inspectionDeadline: deadline,
            auditOrganisationId: organisation.id,
            auditors: [auditor],
            inspectEvery: {
                time,
                times,
            }
        };

        await dispatch(inspectionUpdate(updateData))
    };

    const { archived } = inspection;

    return (
        <div className="tab-edit">
            <div className="content">
                <InputDate
                    label={t("audit.schedule.deadline")}
                    name="deadline"
                    defaultValue={inspection.inspectionDeadline}
                    onChange={(deadline) => { setDeadline(deadline) }}
                />
                <InputPhase
                    setTime={setTime}
                    setTimes={setTimes}
                    defaultValue={inspection.inspectEvery}
                />
                <InputSelect
                    items={userOptions}
                    label={t("audit.schedule.auditor")}
                    name="executor"
                    defaultValue={auditor}
                    onChange={({ target }) => { setAuditor(target.value); }}
                />
            </div>
            <div className="cols">
                <div className="col">
                    <Button
                        danger={!archived}
                        secondary={archived}
                        onClick={archived ? reopen : stop}
                        label={t(archived ? "audit.reopen.button" : "audit.stop.button")}
                    />
                </div>
                <div className="container-buttons col">
                    <Button
                        spacedLeft
                        outlined
                        onClick={onClose}
                        label={t("button.cancel.label")}
                    />
                    <Button
                        spacedLeft
                        onClick={update}
                        label={t("button.save.label")}
                    />
                </div>
            </div>

        </div>
    );
}

TabEdit.propTypes = {
    inspection: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default TabEdit;
