import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_STOPPED } from "redux/types";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";

const stopReview = loader("graphql/mutations/stopReview.graphql");

export default function reviewStop(inspectionId) {
    return async (dispatch) => {
        try {
            const { data } = await client.mutate({
                mutation: stopReview,
                variables: { inspectionId }
            });

            dispatch({
                type: REVIEW_STOPPED,
                review: data.stopReview,
                inspectionId
            });

        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "review.stop.failed")));
        }
    };
}
