import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ICONS } from "shared/components/Icon";
import Button from "../../shared/components/buttons/Button";
import InspectionItem from "../../components/InspectionItem";
import { sortByOrderType } from "helpers/sortByOrderType";
import inspectionsLoad from "redux/actions/audit/inspectionsLoad";
import FilterInspections from "../../components/FilterInspections";
import { booksLoad } from "redux/actions/books/booksLoad";
import SelectedFilters from "../../components/FilterInspections/SelectedFilters";
import Loading from "shared/components/Loading";

import ListHeader from "components/ListHeader";
import Statistiscs from "components/Statistics";

import "./style.scss";


export default function Inspections() {


    const [t] = useTranslation();
    const dispatch = useDispatch();
    const audit = useSelector(state => state.audit);
    const { loading } = useSelector(state => state.audit);
    const [filterActive, setFilterActive] = useState(false);

    const [sortIndex, setSortIndex] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);

    const { inspections } = audit;

    const HEADERS = [
        { field: "organisation", sortField: "label", label: t("organisation.singular") },
        { field: "node", sortField: "label", label: t("audit.location.singular") },
        { field: "book", sortField: "label", label: t("audit.book.singular") },
        { field: "lastInspectionDate", label: t("audit.last") },
        { field: "recoveryPeriod", label: t("audit.recovery.period") },
        { field: "inspectionDeadline", label: t("audit.new") },
        { field: "status", label: t("audit.status.singular") }
    ];

    useEffect(() => {
        dispatch(booksLoad());
        dispatch(inspectionsLoad());
    }, [dispatch]);

    const sortItems = (items) => {
        return Array.isArray(items) && typeof sortIndex === "number"
            ? sortByOrderType(HEADERS, items, sortIndex, sortDirection, t) : items;
    };

    const onSort = (idx) => {
        setSortDirection(!sortDirection);
        setSortIndex(idx);
    };

    const keys = Object.keys(inspections || {});
    const inspectionsArray = keys.map((key) => { return inspections[key]; });

    const renderInspections = () => {
        if (keys.length === 0) {
            return (
                <span className="container-empty">
                    {t("audit.inspections.empty")}
                </span>
            );
        } else {
            return sortItems(inspectionsArray).map((item, index) => {
                return (
                    <InspectionItem
                        key={index}
                        index={index}
                        item={item}
                    />
                );
            });
        }
    };

    const renderButtons = () => {
        return (
            <div className="inspection-overview-buttons">
                {/* <Button
                    label={t("audit.statistics")}
                    onClick={onStatistics}
                    iconRight={ICONS.FILTER}
                    spacedRight
                    outlined /> */}
                <Button
                    label={t("filter.singular")}
                    onClick={() => setFilterActive(!filterActive)}
                    iconRight={ICONS.FILTER}
                    outlined />
            </div>
        );
    };

    return (
        <div className="inspection-overview">
            <div className="overview-header">
                <h2>{t("audit.inspections.title")}</h2>
                {renderButtons()}
            </div>
            {filterActive ?
                <FilterInspections onClose={() => setFilterActive(false)} /> :
                <SelectedFilters />
            }
            <div className="spacer" />
            <div className="filter-divider" />
            <div className="spacer-double" />
            <Statistiscs inspections={inspectionsArray} />
            <div className="spacer-double" />

            {loading ?
                <Loading /> :
                <>
                    <ListHeader
                        onSort={onSort}
                        items={HEADERS}
                        sortIndex={sortIndex}
                        sortDirection={sortDirection}
                    />
                    <div className="inspection-list-items">
                        {renderInspections()}
                    </div>
                </>
            }
        </div>
    );
}