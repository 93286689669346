import produce from "immer";
import { SUBSCRIPTION_SCHEDULED,  SUBSCRIPTIONS_LOADED} from "../types";

const initialState = {
    loading: true,
    subscriptions: [],
    headerItems: [
        { field: "organisation", label: "organisation.singular", sort: false, direction: 0 },
        { field: "location", label: "audit.location.singular", sort: false, direction: 0 },
        { field: "book", label: "audit.book.singular", sort: false, direction: 0 },
        { field: "status", label: "audit.status.singular", sort: false, direction: 0 }
    ]
};

const subscriptionsReducer = produce((draft, action) => {
    switch (action.type) {
        case SUBSCRIPTIONS_LOADED: {
            draft.subscriptions = action.subscriptions;
            draft.loading = false;
            break;
        }
        case SUBSCRIPTION_SCHEDULED: {
            const { scheduled } = action;
            const filtered = draft.subscriptions.filter((subscription) => {
                return subscription.id === scheduled.id;
            })
            draft.subscriptions = filtered;
            break;
        }
        default:
            return;
    }
}, initialState);


export default subscriptionsReducer;