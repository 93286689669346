import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import Icon, { ICONS } from "shared/components/Icon";
import InputText from "shared/components/inputs/InputText";
import Button from "shared/components/buttons/Button";
import Comment from "components/Comment";

import { localeDate } from "helpers/formattedDate";
import { reviewCommentAdd } from "redux/actions/review/reviewCommentAdd";
import { reviewCommentDelete } from "redux/actions/review/reviewCommentDelete";
import { reviewCommentEdit } from "redux/actions/review/reviewCommentEdit";

import "./style.scss";

const QuestionItem = ({
    questionId,
    question,
    answer,
    review
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { inspectionId, id: chapterId } = useParams();
    const [commentText, setCommentText] = useState("");
    const [addingComment, setAddingComment] = useState(false);
    const [editCommentId, setEditCommentId] = useState(undefined);

    const { comments } = review || {};

    if (!question) return null;

    const createAnswer = () => {
        switch (question.type) {
            case "trueOrFalse":
            case "selectOption": {
                const result = question.answers.find((option) => {
                    return option.value === answer.answer;
                });
                return {
                    answer: result.label,
                    indicator: result.auditIndicator,
                    ...answer.explanation && {
                        explanation: answer.explanation,
                        explanationLabel: result.explanation.label,
                    }
                };
            }
            case "openQuestion": {
                return {
                    answer: answer.answer,
                    indicator: question.auditIndicator
                };
            }
            case "dateInput":
                return {
                    answer: localeDate(answer.answer),
                    indicator: question.auditIndicator
                };
            default:
                return null;
        }
    };

    const addComment = async () => {
        await dispatch(reviewCommentAdd(
            inspectionId,
            chapterId,
            questionId,
            commentText
        ));

        setCommentText("");
        setAddingComment(false);
    };

    const updateComment = async () => {
        await dispatch(reviewCommentEdit(
            inspectionId,
            chapterId,
            questionId,
            editCommentId,
            commentText
        ));

        setCommentText("");
        setEditCommentId(undefined);
    };

    const answerInput = createAnswer();

    if (!answerInput) return null;

    const { indicator } = answerInput;

    const answerClasses = classNames(
        "answer",
        indicator
    );

    const indicatorIcons = {
        "GREEN": "check",
        "ORANGE": "exclamation-triangle",
        "RED": "times",
    };

    const commentKeys = comments ? Object.keys(comments) : [];

    return (
        <div className="answer-set">
            <div className="answer-set-inner">
                <div className="content">
                    <span className="question">{question.label}</span>
                    <span className={answerClasses}>
                        <Icon spacedRight name={indicatorIcons[indicator]} />
                        <span>{answerInput.answer}</span>
                    </span>
                    {answerInput.explanation &&
                        <>
                            <div className="spacer" />
                            <span className="question">{answerInput.explanationLabel}</span>
                            <span className={answerClasses}>
                                <span>{answerInput.explanation}</span>
                            </span>
                        </>
                    }
                </div>
                <div className="actions">
                    <Icon
                        onClick={() => setAddingComment(true)}
                        name={ICONS.COMMENT}
                    />
                </div>
            </div>
            <div className="comments">
                {commentKeys.map((key) =>
                    <Comment
                        key={key}
                        comment={comments[key]}
                        canEdit
                        onEdit={() => {
                            // TODO: check if multiple comments possible
                            setCommentText(comments[key].comment);
                            setEditCommentId(key);
                        }}
                        onDelete={() => {
                            dispatch(reviewCommentDelete(
                                inspectionId,
                                chapterId,
                                questionId,
                                key
                            ));
                        }}
                    />
                )}
                <div className="spacer" />
            </div>
            {(addingComment || editCommentId) &&
                <div className="comment-form">
                    <InputText
                        label={t("audit.question.comment")}
                        multiline
                        name="comment"
                        defaultValue={commentText}
                        onChange={({ target }) => setCommentText(target.value)}
                        dark
                    />
                    <div className="container-buttons">
                        <Button
                            onClick={() => setAddingComment(false)}
                            dark
                            outlined
                            label={t("audit.comment.cancel")}
                        />
                        <Button
                            onClick={editCommentId ?
                                updateComment :
                                addComment
                            }
                            spacedLeft
                            dark
                            disabled={commentText.length === 0}
                            label={t("audit.comment.place")}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

QuestionItem.propTypes = {
    questionId: PropTypes.string.isRequired,
    answer: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
    review: PropTypes.object.isRequired,
};

export default QuestionItem;