import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

export const FieldSet = ({ label, value }) => {
    return value ? (
        <div className="field-set">
            <label className="label">{label}</label>
            <span>{value}</span>
        </div>
    ) : null; // display nothing when the field is not available
};

FieldSet.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
};

export default FieldSet;
