import i18n from "i18next";

import {
    SNACKBAR_ERROR,
    SNACKBAR_HIDE,
    SNACKBAR_SUCCESS
} from "../types";

const DEFAULT_DURATION = 5000;

export function snackbarSuccess(message, duration = DEFAULT_DURATION) {
    return async (dispatch) => {
        dispatch({ type: SNACKBAR_SUCCESS, message: i18n.t(message) });
        setTimeout(() => {
            dispatch({ type: SNACKBAR_HIDE });
        }, duration);
    };
}


export function snackbarError(message, duration = DEFAULT_DURATION) {
    return async (dispatch) => {
        dispatch({ type: SNACKBAR_ERROR, message: i18n.t(message) });
        setTimeout(() => {
            dispatch({ type: SNACKBAR_HIDE });

        }, duration);
    };
}
