import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";

import ButtonBack from "shared/components/buttons/ButtonBack";
import ChapterItem from "./ChapterItem";

import "./style.scss";

const Sidebar = () => {

    const [t] = useTranslation();
    const history = useHistory();

    // do something with chapterId (alias of id) in params
    const { inspectionId, id } = useParams();
    const auditReducer = useSelector(state => state.audit);
    const review = useSelector(state => state.review);

    const { inspections } = auditReducer;

    if (!(inspectionId in inspections)) {
        return null;
    }

    const inspection = inspections[inspectionId];

    if (!inspection) {
        return null;
    }

    const { book } = inspection;
    const { progressState, reviewData } = review;
    const { chapters: finishedChapters } = progressState;
    const { chapters } = book;
    const chapterKeys = Object.keys(finishedChapters);
    const { organisation, node } = inspection;

    const overviewClasses = classNames(
        "sidebar-item",
        "overview" === id ? "active" : ""
    );

    const generalClasses = classNames(
        "sidebar-item",
        "content" === id ? "active" : ""
    );

    return (
        <div className="audit-sidebar">
            <ButtonBack
                dark
                label={t("button.back")}
                onClick={() => history.push("/inspections")}
            />
            <div className="spacer" />
            <h3>{`${book.label} ${organisation.label} - ${node.label}`}</h3>
            <div className="scroll-container">
                <div className="sidebar-container">
                    {chapterKeys.map((key, index) => {
                        const { rating } = reviewData.chapters[key] || {};
                        return (
                            <ChapterItem
                                key={index}
                                id={key}
                                chapter={chapters[key]}
                                rating={rating?.rating}
                            />);
                    }
                    )}
                    <Link className={overviewClasses} to={`/inspection/${inspectionId}/resolution/overview`}>
                        <div className="indicator" />
                        {t("audit.overview.label")}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;