import inspectionsStatistics from "helpers/inspectionsStatistics";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Icon, { ICONS } from "shared/components/Icon";

import "./style.scss";

const Statistiscs = ({ inspections }) => {

    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const { users } = useSelector(state => state.users);
    const [statsAuditors, setStatsAuditors] = useState(null);
    const [statsTotal, setStatusTotal] = useState(null);

    // Makes sure we calculate the statistics only once
    useEffect(() => {
        if (inspections) {
            const { statsAuditors, statsTotal } = inspectionsStatistics(inspections);
            setStatsAuditors(statsAuditors);
            setStatusTotal(statsTotal);
        }
    }, [inspections]);


    if (!statsTotal || !statsAuditors) {
        return null;
    }

    const auditorIds = Object.keys(statsAuditors);

    const renderAuditor = (userId, stats) => {

        const { name, email } = users[userId];

        return (
            <div className="stats stats-auditor">
                <div className="stats-auditor-name">
                    {name || email}
                </div>
                <div className="stats-numbers cols">
                    <div className="col">
                        <div className="amount">{stats.late}</div>
                    </div>
                    <div className="col">
                        <div className="amount">{stats.recovery}</div>
                    </div>
                    <div className="col">
                        <div className="amount">{stats.active}</div>
                    </div>
                    <div className="col">
                        <div className="amount">{stats.ok}</div>
                    </div>
                    <div className="col">
                        <div className="amount">{stats.total}</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="container-statistics">
            <div className="stats">
                <div className="stats-title">
                    <h3>
                        {t("audit.statistics.title")}
                    </h3>
                </div>
                <div className="cols-m stats-numbers">
                    <div className="col">
                        <div className="stats-header">
                            <span className="status late" />
                            {t("audit.statistics.late")}
                        </div>
                        <div className="amount">{statsTotal.late}</div>
                    </div>
                    <div className="col">
                        <div className="stats-header">
                            <span className="status recovery" />
                            {t("audit.statistics.recovery")}
                        </div>
                        <div className="amount">{statsTotal.recovery}</div>
                    </div>
                    <div className="col">
                        <div className="stats-header">
                            <span className="status active" />
                            {t("audit.statistics.active")}
                        </div>
                        <div className="amount">{statsTotal.active}</div>
                    </div>
                    <div className="col">
                        <div className="stats-header">
                            <span className="status ok" />
                            {t("audit.statistics.ok")}
                        </div>
                        <div className="amount">{statsTotal.ok}</div>
                    </div>
                    <div className="col">
                        <div className="stats-header">
                            <span className="status total" />
                            {t("audit.statistics.total")}
                        </div>
                        <div className="amount">{inspections.length}</div>
                    </div>
                </div>
            </div>
            {isOpen &&
                <div className="auditor-stats">
                    {auditorIds.map((id) => renderAuditor(id, statsAuditors[id]))}
                </div>
            }
            <div onClick={() => setOpen(!isOpen)} className="stats-show-more">
                {isOpen ? t("audit.statistics.showLess") : t("audit.statistics.showMore")}
                <Icon spacedLeft name={isOpen ? ICONS.UP : ICONS.DOWN} />
            </div>
        </div>
    );
};

Statistiscs.propTypes = {
    inspections: PropTypes.array.isRequired,
};

export default Statistiscs;

