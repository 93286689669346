import produce from "immer";

import { BOOKS_LOADED } from "../types";

const initialState = {
    books: {},
    loading: true,
};

const booksReducer = produce((draft, action) => {
    switch (action.type) {
        case BOOKS_LOADED:
            draft.books = action.books;
            draft.loading = false;
            break;
        default:
            break;
    }
}, initialState);

export default booksReducer;