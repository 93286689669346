import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "shared/components/Icon";
import { MENU_ITEMS } from "constants/menu";
import organisationSet from "redux/actions/organisation/organisationSet";
import { ROLE_LEVELS } from "constants/roles";
import OrganisationSelector, { MODULE_FILTERS } from "shared/components/OrganisationSelector";
import Modules from "shared/components/Modules";

import "./style.scss";

export default function Navigation({ userRoleLevel }) {

    const [t] = useTranslation();
    const dispatch = useDispatch();

    const { organisations, current } = useSelector(state => state.organisation);
    const { user } = useSelector(state => state.user);

    const { role: userRole } = user?.membership?.okAudit || {};

    const menuItems = () => {
        return MENU_ITEMS.map((menuItem, index) => {
            const { label, path, iconName, className, role } = menuItem;
            if (role && ROLE_LEVELS[userRole] < ROLE_LEVELS[role]) {
                return null;
            }
            const classes = classnames("menu-item", className ? className : "");
            return (
                <NavLink
                    key={`navigation-${index}`}
                    className={classes}
                    to={path}
                    activeClassName="is-active">
                    <Icon name={iconName} spacedRight />
                    <span className="has-text-white">
                        {t(label)}
                    </span>
                </NavLink>
            );
        });
    };
    return (
        <nav className="navigation" role="navigation">
            <div className="main-navigation">
                <div className="navigation-left">
                    <Link className="navigation-logo" to="/">
                        <img
                            alt="OKcomply Books"
                            src={require("../../assets/img/logo.png")}
                        />
                        <span>AUDIT</span>
                    </Link>
                    <div className="menu-items">
                        {menuItems()}
                    </div>
                </div>
                <div className="navigation-right">
                    <div className="navigation-big organisation">
                        {organisations?.length > 0 &&
                            <OrganisationSelector
                                filterModule={MODULE_FILTERS.AUDIT}
                                organisations={organisations}
                                onOrganisationChanged={async (index) => {
                                    await dispatch(organisationSet(index));
                                    window.location.reload();
                                }}
                            />
                        }
                    </div>
                    <Modules organisation={current} />
                </div>

            </div>
        </nav>
    );
}

Navigation.propTypes = {
    userRoleLevel: PropTypes.object
};

