import Cookies from "universal-cookie";

export function clearSiteData() {
    // Clear the auth part of local storage
    localStorage.removeItem("persist:root");
    localStorage.removeItem("organisationId");
    const cookies = new Cookies();
    cookies.remove("access_token");
    cookies.remove("id_token");
    cookies.remove("expires_at");

    // Clear all other session data from cookies
    var otherCookies = document.cookie.split(";");
    for (var i = 0; i < otherCookies.length; i++) {
        var cookie = otherCookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
