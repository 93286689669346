import React, { useState } from "react";
import PropTypes from "prop-types";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import format from "date-fns/format";
import nlLocale from "date-fns/locale/nl";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import "./style.scss";
import { Controller } from "react-hook-form";

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, "d MMM yyyy", { locale: this.locale });
    }
}


const InputDate = ({
    defaultValue,
    name,
    label,
    dark,
    inputRef,
    error,
    minDate,
    maxDate,
    onChange,
    testId,
    control,
    rules,
    onBlur
}) => {

    const [selectedDate, setSelectedDate] = useState(new Date(defaultValue));

    const classes = classNames(
        "input-date",
        dark ? "dark" : ""
    );

    const handleDateChange = (date) => {
        setSelectedDate(date);
        onChange && onChange(date);
    };

    const [t] = useTranslation();

    // Check for error messages
    let errorMessage = "";
    if (error) {
        errorMessage = error.message || t(`formValidation.${error.type}`);
    }


    const renderField = () => {
        return (
            <KeyboardDatePicker
                onBlur={onBlur}
                minDate={minDate}
                maxDate={maxDate}
                margin="normal"
                className={classes}
                name={name}
                label={label}
                helperText={errorMessage}
                error={!!error}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={selectedDate}
                inputRef={inputRef}
                onChange={handleDateChange}
                inputProps={{
                    "data-testid": testId
                }}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
            />

        );
    };

    return (
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={nlLocale}>
            {control ?
                // Render controlled field
                <Controller
                    as={renderField()}
                    rules={rules}
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                /> :
                // Render uncontrolled field
                renderField()}
        </MuiPickersUtilsProvider>

    );
};


InputDate.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    helpText: PropTypes.string,
    multiline: PropTypes.bool,
    error: PropTypes.string,
    inputRef: PropTypes.func,
    type: PropTypes.string,
    dark: PropTypes.bool,
    readOnly: PropTypes.bool,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    testId: PropTypes.string,
    control: PropTypes.object,
    rules: PropTypes.object,
    onBlur: PropTypes.func
};


export default InputDate;