import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Icon, { ICONS } from "shared/components/Icon";


import "./style.scss";

const ButtonFlow = ({ isNext, onClick, disabled }) => {

    const classes = classnames(
        "button-flow",
        disabled ? "disabled" : ""
    );

    return (
        <span
            onClick={onClick}
            className={classes}>
            <Icon name={isNext ? ICONS.FORWARD : ICONS.BACK} />
        </span>
    );
};

ButtonFlow.propTypes = {
    isNext: PropTypes.bool,
    disabled: PropTypes.bool
};

export default ButtonFlow;