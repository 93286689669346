import moment from "moment";

export default function formattedDate(dateField) {
    return moment(dateField).format("YYYY-MM-DD");
}

export function localeDate(dateField) {
    return moment(dateField).format("ll");
}

export function localeTime(dateField) {
    return moment(dateField).format("LT");
}

export function isValidStringDate(date) {
    const convertedDate = new Date(date);
    return convertedDate instanceof Date && !isNaN(convertedDate);
}