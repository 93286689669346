import InputPhase from "components/InputPhase";
import usersToSelectOptions from "helpers/usersToSelectOptions";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { inspectionSchedule } from "redux/actions/schedule/inspectionSchedule";
import Button from "shared/components/buttons/Button";
import InputDate from "shared/components/inputs/InputDate";
import InputSelect from "shared/components/inputs/InputSelect";

import "./style.scss";

const ScheduleModal = ({ subscription, onClose }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const organisation = useSelector(state => state.organisation.current);
    const { users } = useSelector(state => state.users);
    const userOptions = usersToSelectOptions(users);

    const [deadline, setDeadline] = useState(new Date());
    const [time, setTime] = useState("years");
    const [times, setTimes] = useState(1);
    const [auditor, setAuditor] = useState(userOptions[0].value);
    
    const scheduleConfirmed = async () => {

        const scheduleData = {
            organisationId: subscription.organisation.id,
            nodeId: subscription.node.id,
            osbn: subscription.book.osbn,
            inspectionDeadline: deadline,
            auditOrganisationId: organisation.id,
            auditors: [auditor],
            subscriptionId: subscription.id,
            inspectEvery: {
                time,
                times,
            }
        };

        await dispatch(inspectionSchedule(scheduleData))

        onClose();

    };



    return (
        <div className="schedule-modal-container">
            <div onClick={onClose} className="modal-background" />
            <div className="schedule-modal">
                <header className="modal-head">
                    <h3 className="modal-title">{(t("audit.schedule.title"))}</h3>
                    <button
                        className="delete is-large"
                        aria-label="close"
                        onClick={onClose}
                    />
                </header>
                <section className="modal-body">
                    <InputDate
                        label={t("audit.schedule.deadline")}
                        defaultValue={new Date()}
                        onChange={(deadline) => { setDeadline(deadline) }}
                    />
                    <InputPhase
                        setTime={setTime}
                        setTimes={setTimes}
                    />
                    <InputSelect
                        items={userOptions}
                        label={t("audit.schedule.auditor")}
                        name="executor"
                        defaultValue={auditor}
                        onChange={({ target }) => { setAuditor(target.value); }}
                    />
                    <div className="container-buttons">
                        <Button
                            outlined
                            onClick={onClose}
                            label={t("button.cancel.label")}
                        />
                        <Button
                            spacedLeft
                            onClick={scheduleConfirmed}
                            label={t("audit.schedule.confirm")}
                        />

                    </div>
                </section>
            </div>
        </div>
    );
}

ScheduleModal.propTypes = {
    subscription: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ScheduleModal;