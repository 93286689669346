
export const REVIEW_STATE = {
    NEW: "NEW", // Review just started, no / empty reviewData
    IN_PROGRESS: "IN_PROGRESS", // Review given, not at end of progress yet
    PENDING: "PENDING", // All book progress is reviewed
    FINISHED: "FINISHED" // Review completed, resolution created
};

export const REVIEW_RATING = {
    APPROVED: 1,
    DISAPPROVED: -1
};

export const PROVINCES = [
    { label: "Brabant", value: "Brabant" },
    { label: "Drenthe", value: "Drenthe" },
    { label: "Flevoland", value: "Flevoland" },
    { label: "Friesland", value: "Friesland" },
    { label: "Gelderland", value: "Gelderland" },
    { label: "Groningen", value: "Groningen" },
    { label: "Limburg", value: "Limburg" },
    { label: "Noord-Holland", value: "Noord-Holland" },
    { label: "Overijssel", value: "Overijssel" },
    { label: "Utrecht", value: "Utrect" },
    { label: "Zeeland", value: "Zeeland" },
    { label: "Zuid-Holland", value: "Zuid-Holland" },
    { label: "Buitenland", value: "Buitenland" },
];
