import produce from "immer";

import { USERS_LOADED } from "../types";

const initialState = {
    users: {},
    loading: true,
};

const userReducer = produce((draft, action) => {
    switch (action.type) {
        case USERS_LOADED:
            draft.users = action.users;
            break;
        default:
            return;
    }
}, initialState);


export default userReducer;