import produce from "immer";
import { 
    DOSSIER_ACTIVITY_LOADED, 
    DOSSIER_LOADED 
} from "../types";

const initialState = {
    dossiers: {},
    loading: true,
};

const dossiersReducer = produce((draft, action) => {
    switch (action.type) {
        case DOSSIER_LOADED: {
            const { dossier } = action;
            draft.loading = false;
            draft.dossiers[dossier.id] = dossier;
            break;
        }
        case DOSSIER_ACTIVITY_LOADED: {
            const { activity, draftDossierId } = action;
            draft.loading = false;
            draft.dossiers[draftDossierId].activity = activity;
            break;     
        }
        default:
            return;
    }
}, initialState);


export default dossiersReducer;