import { combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import auditReducer from "../reducers/auditReducer";
import snackbarReducer from "../../shared/redux/reducers/SnackbarReducer";
import subscriptionsReducer from "../reducers/subscriptionsReducer";
import organisationReducer from "../reducers/organisationReducer";
import reviewReducer from "redux/reducers/reviewReducer";
import inspectionHistoryReducer from "redux/reducers/inspectionHistoryReducer";
import usersReducer from  "redux/reducers/usersReducer";
import booksReducer from "redux/reducers/booksReducer";
import dossiersReducer from "redux/reducers/dossiersReducer";

export default combineReducers({
    user: userReducer,
    audit: auditReducer,
    review: reviewReducer,
    snackbar: snackbarReducer,
    organisation: organisationReducer,
    subscriptions: subscriptionsReducer,
    inspectionHistory: inspectionHistoryReducer,
    books: booksReducer,
    users: usersReducer,
    dossiers: dossiersReducer
});