// By lack of better components as an actual customizable snackbar, we created one ourselves
import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import { useSelector } from "react-redux";

import "./style.scss";

export const TYPES = {
    SNACKBAR_SUCCESS: {
        icon: "check",
        class: "success",
    },
    SNACKBAR_ERROR: {
        icon: "exclamation-triangle",
        class: "error",
    }
};

function OkSnackbar({ fixed }) {

    const snackbar = useSelector(state => state.snackbar);
    
    if (snackbar && snackbar.show) {
        const { show, type, message } = snackbar;
        const classes = classnames(
            "okcomply-snackbar",
            TYPES[type].class,
            fixed ? "fixed" : "",
            show ? "show" : "hide",
        );
        return (
            <div className={classes}>
                <FontAwesomeIcon
                    className="icon"
                    icon={TYPES[type].icon}
                />
                <span className="snackbar-text">
                    {message}
                </span>
            </div>
        );
    } else {
        return null;
    }
}

OkSnackbar.propTypes = {
    fixed: PropTypes.bool,
};


export default OkSnackbar;
