import React from "react";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./style.scss";
import { Controller } from "react-hook-form";

export default function InputText({
    label,
    name,
    onChange,
    onKeyDown,
    error,
    inputRef,
    multiline,
    value,
    defaultValue,
    control,
    rules,
    onBlur,
    type,
    dark,
    inputLabelProps,
    readOnly,
    iconLeft,
    iconRight,
    dataTestId,
    disabled = false,
    small,
    noMarginBottom
}) {

    const classes = classNames(
        "input-text",
        dark ? "dark" : "",
        error ? "error" : "",
        noMarginBottom ? "no-margin-bottom" : ""
    );

    const [t] = useTranslation();

    // Check for error messages
    let errorMessage = "";
    if (error) {
        errorMessage = error.message || t(`formValidation.${error.type}`);
    }

    const hasIconLeft = () => {
        return (
            iconLeft ?
                <InputAdornment position="start">
                    {iconLeft}
                </InputAdornment> : null
        );
    };

    const hasIconRight = () => {
        return (
            iconRight ?
                <InputAdornment position="end">
                    {iconRight}
                </InputAdornment> : null
        );
    };

    const renderField = () => {
        return (
            <div className={classes}>
                <TextField
                    label={label}
                    onKeyDown={(e) => {
                        onKeyDown && onKeyDown(e);
                    }}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    InputLabelProps={inputLabelProps}
                    onChange={onChange}
                    disabled={disabled}
                    error={!!error}
                    helperText={errorMessage}
                    inputRef={inputRef}
                    variant="outlined"
                    type={type}
                    fullWidth
                    readOnly={readOnly}
                    multiline={multiline}
                    rows={multiline ? 3 : 1}
                    style={small ? { width: "72px" } : {}}
                    onBlur={onBlur}
                    InputProps={{
                        readOnly: readOnly,
                        startAdornment: hasIconLeft(),
                        endAdornment: hasIconRight(),
                        "data-testid": dataTestId
                    }}
                />
            </div>
        );
    };

    return (
        control ?
            // Render controlled field
            <Controller
                as={renderField()}
                rules={rules}
                name={name}
                control={control}
                defaultValue={defaultValue}
            /> :
            // Render uncontrolled field
            renderField()
    );
}

InputText.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    control: PropTypes.object,
    rules: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    helpText: PropTypes.string,
    multiline: PropTypes.bool,
    error: PropTypes.bool,
    inputRef: PropTypes.func,
    type: PropTypes.string,
    dark: PropTypes.bool,
    small: PropTypes.bool,
    inputLabelProps: PropTypes.object,
    iconLeft: PropTypes.object,
    iconRight: PropTypes.object,
    readOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    noMarginBottom: PropTypes.bool
};
