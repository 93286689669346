import { REVIEW_STATE } from "constants/audit";

export default function inspectionStatus(inspection) {
    const { 
        review, 
        inspectionDeadline, 
        archived, 
        recoveryPeriod 
    } = inspection;

    let status = "ok";

    if (archived) {
        status = "archived";
    } else if (recoveryPeriod) {
        status = "recovery";
    } else if (
        review?.status === REVIEW_STATE.NEW ||
        review?.status === REVIEW_STATE.IN_PROGRESS
    ) {
        status = "active";
    } else if (new Date(inspectionDeadline) < new Date()) {
        status = "late";
    }

    return status;
}