import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import inspectionStatus from "helpers/inspectionStatus";
import { useTranslation } from "react-i18next";

import "./style.scss";

const Status = ({ inspection }) => {

    const { t } = useTranslation();
    const status = inspectionStatus(inspection);
    const classes = classNames(
        "inspection-status",
        status
    );


    return (
        <span className={classes}>
            {t(`inspection.status.${status}`)}
        </span>
    );
};

export default Status;

Status.propTypes = {
    inspection: PropTypes.object.isRequired
};