import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Button from "shared/components/buttons/Button";
import ButtonAdd from "shared/components/buttons/ButtonAdd";
import InputText from "shared/components/inputs/InputText";
import Comment from "components/Comment";

import "./style.scss";
import { useSelector } from "react-redux";

const Notes = ({ notes, onChange }) => {

    const { t } = useTranslation();
    const { register, handleSubmit, errors } = useForm();
    const [showInput, setShowInput] = useState(false);
    const [edit, setEdit] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const { id: userId } = useSelector(state => state.user.user);
    
    const onSubmit = (formData) => {
        if (isEditing) {
            const newNotes = [...notes];
            newNotes[edit.index] = {
                ...formData,
                timestamp: new Date(),
                userId
            };
            onChange(newNotes);

        } else {
            const newNotes = [...notes,{
                ...formData,
                timestamp: new Date(),
                userId
            }
            ];
            onChange(newNotes);
        }

        close();
    };

    const close = () => {
        setShowInput(false);
        setIsEditing(false);
        setEdit({});
    }

    const onEdit = async (index) => {
        await setShowInput(false);
        await setEdit({ index, ...notes[index] });
        setIsEditing(true);
        setShowInput(true);
    };

    const onDelete = (index) => {
        const newNotes = [...notes];
        newNotes.splice(index, 1);
        onChange(newNotes);
        close();
    };

    return (
        <div className="notes">
            <div className="spacer" />
            {notes?.map((note, index) =>
                <Comment
                    key={index}
                    index={index}
                    comment={note}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    canEdit
                />
            )}
            <ButtonAdd
                label={t("note.button")}
                onClick={setShowInput}
            />
            {showInput &&
                <div className="container-input">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h4 className="light">
                            {isEditing ? t("note.edit.title") : t("note.add.title")}
                        </h4>
                        <div className="spacer" />
                        <div className="cols">
                            <div className="col">
                                <InputText
                                    dark
                                    label={t("note.text")}
                                    name="text"
                                    error={errors.text}
                                    defaultValue={edit.text}
                                    inputRef={register({ required: true })}
                                    multiline
                                />
                            </div>
                        </div>
                        <div className="container-buttons">
                            <Button
                                onClick={close}
                                dark
                                outlined
                                label={t("note.cancel.button")}
                            />
                            <Button
                                type="submit"
                                spacedLeft
                                dark
                                label={isEditing ? 
                                    t("note.edit.button") : 
                                    t("note.add.button")}
                            />
                        </div>
                    </form>
                </div>
            }
        </div>
    );
};

Notes.propTypes = {
    onChange: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
};

export default Notes;