import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { DOSSIER_LOADED } from "../../types";

const bookDossier = loader("graphql/queries/bookDossier.graphql");

export function dossierLoad(inspectionId, dossierId) {
    return async (dispatch, getState) => {
        const { id: organisationId } = getState().organisation.current;
        const { data } = await client.query({
            query: bookDossier,
            variables: { 
                dossierId, 
                inspectionId,
                organisationId
            }
        });

        const { bookDossier: dossier } = data;

        dispatch({ type: DOSSIER_LOADED, dossier });
    };
}
