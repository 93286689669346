import inspectionStatus from "./inspectionStatus";

export default function inspectionsStatistics(inspections) {

    const statisticsEmpty = {
        archived: 0,
        recovery: 0,
        ok: 0,
        active: 0,
        late: 0,
        total: 0,
    };

    const statsTotal = { ...statisticsEmpty };
    const statsAuditors = {};

    if (!inspections) return;

    inspections.forEach((inspection) => {
        const status = inspectionStatus(inspection);

        statsTotal[status] !== undefined
            && statsTotal[status]++;

        inspection.auditors.forEach((auditor) => {
            if (!statsAuditors[auditor.id]) {
                statsAuditors[auditor.id] = { ...statisticsEmpty };
            }
            if (statsAuditors[auditor.id][status] !== undefined) {
                statsAuditors[auditor.id][status]++;
                statsAuditors[auditor.id].total++;
            }
        });
    });

    return { statsAuditors, statsTotal };
}