import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { INSPECTION_UPDATED } from "redux/types";

const editBookInspection = loader("graphql/mutations/editBookInspection.graphql");

export default function inspectionUpdate(updateData) {
    return async (dispatch) => {
        try {
            // TODO: use this result?
            const { data } = await client.mutate({
                mutation: editBookInspection,
                variables: updateData
            });

            dispatch(snackbarSuccess("audit.inspection.updated"));
            dispatch({
                type: INSPECTION_UPDATED,
                inspection: data.editBookInspection
            });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error)));
        }
    };
}
