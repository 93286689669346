import inspectionStatus from "helpers/inspectionStatus";

export function sortByOrderType(types, values, orderType, direction, t) {
    const sortedValues = values.sort((a, b) => {
        const type = types[orderType].field;
        const sortField = types[orderType].sortField;
        let valueA = a[type];
        let valueB = a[type];

        if (typeof sortField === "string") {
            valueA = a[type][sortField];
            valueB = b[type][sortField];
        }

        if (type === "status") {
            valueA = inspectionStatus(a);
            valueB = inspectionStatus(b);
            valueA = t(`inspection.status.${valueA}`);
            valueB = t(`inspection.status.${valueB}`);
        }
        else if (
            type === "lastInspectionDate" ||
            type === "recoveryPeriod" ||
            type === "inspectionDeadline"
        ) {
            valueA = new Date(valueA);
            valueB = new Date(valueB);
        }

        if (valueA < valueB) {
            return 1;
        }
        else if (valueA > valueB) {
            return -1;
        }
        return 0;
    });

    return direction ? sortedValues : sortedValues.reverse();
}