import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { USER_LOADED } from "../../types";

const fetchUserProfile = loader("shared/graphql/queries/users/fetchUserProfile.graphql");
const fetchUserMembership = loader("shared/graphql/queries/users/fetchUserMembership.graphql");


export function userLoad() {
    return async (dispatch, getState) => {

        const { id: organisationId } = getState().organisation.current;
        const profileResponse = await client.query({ query: fetchUserProfile });
        const { userProfile } = profileResponse.data;

        const membershipResponse = await client.query({
            query: fetchUserMembership,
            variables: {
                userId: userProfile.id,
                organisationId
            }
        });
        const { userMembership: membership } = membershipResponse.data;

        dispatch({
            type: USER_LOADED,
            user: { ...userProfile,  membership }
        });
    };
}
