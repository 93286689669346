import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import isAuthenticated from "./shared/authentication/isAuthenticated";
import Navigation from "./components/Navigation";
import InspectionsActive from "./containers/InspectionsActive";
import Inspection from "./containers/InspectionModal";
import Review from "./containers/Review";
import Schedule from "./containers/Schedule";
import organisationLoadAll from "redux/actions/organisation/organisationLoadAll";
import { userLoad } from "redux/actions/users/userLoad";
import usersLoad from "redux/actions/users/usersLoad";
import InspectionsArchived from "containers/InspectionsArchived";
import Authentication from "shared/authentication/Authentication";


let rootHistory = undefined;

export function setRootHistory(history) {
    rootHistory = history;
}

export function getRootHistory() {
    return rootHistory;
}

export default function AppRouter() {

    const history = useHistory();
    const dispatch = useDispatch();

    setRootHistory(history);

    const organisationReducer = useSelector(state => state.organisation);
    const { loading } = organisationReducer;


    useEffect(() => {
        const path = history.location.pathname;
        if (!isAuthenticated() && path !== "/login") {
            history.push("/login");
        }
    }, [history]);

    const loadAll = async ()=> {
        await dispatch(organisationLoadAll());
        await dispatch(usersLoad());
        await dispatch(userLoad());
    };

    useEffect(() => {
        if (isAuthenticated()) {
            loadAll();
        }
    }, [dispatch]);

    const unAuthenticatedRoutes = () => {
        return (
            <Route
                path="/login"
                render={() =>
                    <Authentication defaultRoute="/inspections" />
                }
            />

        );
    };

    const authenticatedRoutes = () => {

        return !loading ? (
            <div className="App">
                <Navigation />
                <Route
                    default
                    path="/inspections*"
                    component={InspectionsActive} />
                <Route
                    default
                    path="/archived*"
                    component={InspectionsArchived} />
                <Route
                    exact
                    default
                    path="/inspections/:inspectionId/:tab"
                    component={Inspection} />
                <Route
                    exact
                    default
                    path="/archived/:inspectionId/:tab"
                    component={Inspection} />
                <Route
                    exact
                    path="/inspection/:inspectionId/:section/:id"
                    component={Review} />
                <Route
                    exact
                    default
                    path="/schedule"
                    component={Schedule} />
            </div>
        ) : <div />;
    };

    return (
        <Switch>
            <>
                {isAuthenticated() ?
                    authenticatedRoutes() :
                    unAuthenticatedRoutes()
                }
            </>
        </Switch>
    );
}