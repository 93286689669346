import { loader } from "graphql.macro";
import client from "../../../graphql/client";
import createErrorMessage from "helpers/createErrorMessage";
import { snackbarError } from "shared/redux/actions/Snackbar";
import { SUBSCRIPTIONS_LOADED } from "redux/types";

const auditSubscriptionsQuery = loader("graphql/queries/auditSubscriptions.graphql");

export function subscriptionsLoad() {
    return async (dispatch, getState) => {
        try {

            const { id: auditOrganisationId } = getState().organisation.current;

            const response = await client.query({
                query: auditSubscriptionsQuery,
                variables: { auditOrganisationId },
                fetchPolicy: "network-only"
            });

            const { auditSubscriptions } = response.data;

            dispatch({
                type: SUBSCRIPTIONS_LOADED,
                subscriptions: auditSubscriptions
            });

        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error)));
        }
    };
}
