import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import reviewLoadCurrent from "redux/actions/review/reviewLoadCurrent";
import Chapter from "./sections/Chapter";
import Resolution from "./sections/Resolution";
import Sidebar from "./Sidebar";
import MobileNavigation from "shared/components/MobileNavigation";

import "./style.scss";


const renderSection = {
    "review": <Chapter />,
    "resolution":  <Resolution />,
};

const Review = () => {

    const { inspectionId, section } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(reviewLoadCurrent(inspectionId));
    }, []);
    
 
    return (
        <div className="audit-details">
            <Sidebar />
            <div className="audit-content">
                <MobileNavigation title={t("review.mobileNavigation")} />
                {renderSection[section]}
            </div>
        </div>
    );
};

export default Review;