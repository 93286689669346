import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import InputText from "shared/components/inputs/InputText";
import { snackbarError } from "shared/redux/actions/Snackbar";
import Icon, { ICONS } from "shared/components/Icon";
import ButtonAnchor from "shared/components/buttons/ButtonAnchor";
import { userResetPassword } from "shared/helpers/userResetPassword";

const AuthPasswordReset = ({ setPasswordReset, email }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        errors,
        setError,
        clearErrors,
    } = useForm();

    const [success, setSuccess] = useState(false);

    const onSubmit = async (formData, e) => {
        try {
            const { email } = formData;
            await userResetPassword(email);
        } catch (error) {
            dispatch(snackbarError("common.passwordResetError"));
        }
    };

    const validateEmail = (event) => {
        const { value } = event.target;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            setError("email", { message: t("validation.required.email") });
        } else {
            clearErrors("email");
        }
    };

    const renderPasswordResetForm = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="password-reset">
                    <div className="header">
                        <h4>{t("common.password.reset")}</h4>
                    </div>
                    <div className="text">
                        {t("common.password.resetText")}
                    </div>
                    <div className="spacer-double" />
                    <InputText
                        label={t("user.email.label")}
                        name="email"
                        defaultValue={email || ""}
                        error={errors.email}
                        inputRef={register({ required: true })}
                        onBlur={validateEmail}
                        fullWidth
                    />
                    <input
                        type="submit"
                        className="button is-success is-fullwidth"
                        value={t("common.send")}
                    />
                    <ButtonAnchor onClick={() => setPasswordReset(false)} label={t("common.password.back")} />
                </div>
            </form>);
    };

    const renderResetSuccess = () => {
        return (
            <>
                <div className="password-reset">
                    <div className="text">
                        <div>
                            <Icon name={ICONS.ENVELOPE} size={"2x"} />
                        </div>
                        <div>{t("common.password.resetDone")}</div>
                    </div>
                    <div className="spacer" />
                    <input
                        type="button"
                        className="button is-success is-fullwidth"
                        value={t("common.password.resetBackToLogin")}
                        onClick={() => setPasswordReset(false)} />
                </div>
            </>);
    };


    return (
        <div className="container-card-centered">
            {success ?
                renderResetSuccess() :
                renderPasswordResetForm()}
        </div>
    );
};

AuthPasswordReset.propTypes = {
    email: PropTypes.string,
    setPasswordReset: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired
};


export default AuthPasswordReset;
