import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_STARTED } from "redux/types";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";


const startBookInspectionReviewMutation = loader("graphql/mutations/startBookInspectionReview.graphql");

export default function reviewStart(inspectionId, startNew) {
    return async (dispatch, getState) => {

        try {
            const inspection = getState().audit.inspections[inspectionId];
            const { inspectionId: currentReviewInspection } = getState().review;

            let review = null;
            
            if (startNew) {
                const response = await client.mutate({
                    mutation: startBookInspectionReviewMutation,
                    variables: { inspectionId }
                });

                const { startBookInspectionReview: resultReview } = response.data;
                const { progressState } = resultReview;

                review = {
                    ...resultReview,
                    reviewData: {
                        chapters: {}
                    },// should be empty state from okapi
                    progressState: {
                        ...progressState,
                        chapters: JSON.parse(progressState.chapters),
                        preFlow: progressState?.preFlow ? JSON.parse(progressState.preFlow) : {}
                    }
                };
            } else {
                if (inspectionId === currentReviewInspection) {
                    // Already open, nothing to do
                    return;
                } else {
                    review = inspection.review;
                }
            }

            dispatch({
                type: REVIEW_STARTED,
                inspectionId,
                review,
            });

        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "review.start.failed")));
        }
    };
}
