import isDevelopment from "./isDevelopment";
import isTest from "./isTest";

export default function getLoginOptions() {
    const callbackUrl = `${window.location.origin}/login`;

    return {
        domain: isDevelopment() || isTest() ?
            process.env.REACT_APP_AUTH0_DOMAIN :
            "login.okcomply.com",
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        allowedConnection: process.env.REACT_APP_AUTH0_ALLOWED_CONNECTION,
        responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
        redirectUri: callbackUrl,
        callbackUrl: callbackUrl,
        scope: "openid profile email"
    };
}