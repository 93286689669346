import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useSelector } from "react-redux";
import { ROLES, ROLE_LEVELS } from "constants/roles";

export default function SubscriptionItem({ subscription, onSchedule }) {

    const { t } = useTranslation();
    const { user } = useSelector(state => state.user);

    const { organisation, node, book } = subscription;
    const { role: userRole } = user.membership.okAudit;


    const renderAddress = () => {
        if (!node.attributes || node.attributes.length === 0) {
            return;
        }
        const { address, city, postcode, region } = node.attributes[0] || {};
        return (
            <>
                <span>{address}</span>
                <br />
                <span>{postcode}</span>
                <span>{city}</span>
                <br />
                <span>{region}</span>
            </>
        );
    };
    
    return (
        <div className="audit-list-item subscription-item">
            <div className="list-item-rows">
                <div className="list-item-row-single">
                    <div className="list-item-label bold">
                        <div className="label-modal">{organisation?.label || "Unknown"}</div>
                    </div>
                </div>
                <div className="list-item-row-single">
                    <div className="locationCircle">
                        <img className={"imgLocation has-pointer"} alt={""} />
                        <span className="tooltip-desktop">
                            {renderAddress()}
                        </span>
                    </div>
                    <div className="list-item-label">
                        <div className="label-modal is-marginless">{node.label}</div>
                    </div>
                </div>
                <div className="list-item-row-single">
                    <div className="list-item-label">
                        <div className="label-modal is-marginless">{book.label}</div>
                    </div>
                </div>
                <div className="list-item-row-single">
                    {ROLE_LEVELS[userRole] >= ROLE_LEVELS[ROLES.QUALITY_MANAGER] &&
                        <div
                            onClick={() => onSchedule(subscription)}
                            className="list-item-label button-schedule"
                        >
                            <div className="label-modal is-marginless">{t("audit.schedule.open")}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};


SubscriptionItem.propTypes = {
    subscription: PropTypes.object.isRequired,
    onSchedule: PropTypes.func.isRequired
};

