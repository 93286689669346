// import "./style.scss";
import React from "react";
import PropTypes from "prop-types";
import RadioButton from "./RadioButton";

import "./style.scss";

export default function RadioSelector(props) {

    const {
        firstLabel,
        secondLabel,
        selected,
        onHandleSelected
    } = props;

    const onRadioSelected = (index) => {
        // Convert to boolean, because we only have 2 radio
        // buttons states here
        onHandleSelected(index === 1);
    };

    return (
        <div className="cols">
            <div className="col">
                <RadioButton
                    onHandleSelected={onRadioSelected}
                    label={firstLabel}
                    selected={selected}
                    value={1}
                />
            </div>
            <div className="col">
                <RadioButton
                    onHandleSelected={onRadioSelected}
                    label={secondLabel}
                    selected={!selected}
                    value={0}
                />
            </div>
        </div>
    );

}

RadioSelector.propTypes = {
    firstLabel: PropTypes.string.isRequired,
    secondLabel: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onHandleSelected: PropTypes.func.isRequired
};
