import React from "react";
import PropTypes from "prop-types";
import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";

import "./style.scss";

const MobileNavigation = ({title, onClick}) => {
    return ( 
    <div onClick={onClick} className="mobile-navigation">
        {title}
        <Icon name={ICONS.LIST}/>
    </div> 
    );
};


MobileNavigation.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default MobileNavigation;