import React from "react";
import PropTypes from "prop-types";
import DossierItem from "../../../../../components/DossierItem";

import "./style.scss";
import { useParams } from "react-router";

const Result = ({ draftDossiers }) => {

    const { inspectionId } = useParams();

    return (
        <div className="chapter-resulted">
            {draftDossiers?.length > 0 &&
                <>
                    {draftDossiers.map((draftDossierId, index) =>
                        <DossierItem
                            key={index}
                            inspectionId={inspectionId}
                            draftDossierId={draftDossierId}
                        />
                    )}
                </>
            }
        </div>
    );
};

Result.propTypes = {
    draftDossiers: PropTypes.array.isRequired,
};


export default Result;