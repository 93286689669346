// Users
export const USER_LOADED = "USER_LOADED";
export const USERS_LOADED = "USERS_LOADED";

// Organisation
export const ORGANISATION_LOADED = "ORGANISATION_LOADED";
export const ORGANISATIONS_LOADED = "ORGANISATIONS_LOADED";

// audit reducer
export const AUDIT_SAVE_INVITES = "AUDIT_SAVE_INVITES";
export const AUDIT_SAVE_INSPECTION_ITEMS = "AUDIT_SAVE_INSPECTION_ITEMS";
export const AUDIT_SET_ACTIVE_HEADER_ITEM = "AUDIT_SET_ACTIVE_HEADER_ITEM";
export const AUDIT_SAVE_HEADER_ITEMS = "AUDIT_SAVE_HEADER_ITEMS";

// Reviews
export const REVIEW_COMMENT_PLACED = "REVIEW_COMMENT_PLACED";
export const REVIEW_COMMENT_DELETED = "REVIEW_COMMENT_DELETED";
export const REVIEW_COMMENT_EDITED = "REVIEW_COMMENT_EDITED";
export const REVIEW_STOPPED = "REVIEW_STOPPED";
export const REVIEW_STARTED = "REVIEW_STARTED";
export const REVIEW_CHAPTER_RATED = "REVIEW_CHAPTER_RATED";
export const REVIEW_FINISHED = "REVIEW_FINISHED";
export const REVIEW_LOADED = "REVIEW_LOADED";
export const REVIEW_LOADING = "REVIEW_LOADING";

// Subscriptions
export const SUBSCRIPTIONS_LOADED = "SUBSCRIPTIONS_LOADED";
export const SUBSCRIPTION_SCHEDULED = "SUBSCRIPTION_SCHEDULED";

// Inspections
export const INSPECTION_HISTORY_LOADED = "INSPECTION_HISTORY_LOADED";
export const INSPECTION_UPDATED = "INSPECTION_UPDATED";
export const INSPECTION_REPORT_EMAIL_SENT = "INSPECTION_REPORT_EMAIL_SENT";
export const INSPECTION_ARCHIVED = "INSPECTION_ARCHIVED";
export const INSPECTION_REOPENED = "INSPECTION_REOPENED;"
export const INSPECTIONS_FILTER ="INSPECTIONS_FILTER";
export const INSPECTIONS_FILTER_REMOVE = "INSPECTIONS_FILTER_REMOVE";
export const INSPECTIONS_FILTER_RESET_DATE = "INSPECTIONS_FILTER_RESET_DATE";
export const INSPECTIONS_LOADING ="INSPECTIONS_LOADING";
export const INSPECTIONS_ARCHIVED_LOADED = "INSPECTIONS_ARCHIVED_LOADED";

// General
export const BOOKS_LOADED = "BOOKS_LOADED";
export const DOSSIER_LOADED = "DOSSIER_LOADED";
export const DOSSIER_ACTIVITY_LOADED = "DOSSIER_ACTIVITY_LOADED";
