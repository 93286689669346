import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import InputText from "shared/components/inputs/InputText";
import Button from "shared/components/buttons/Button";
import ButtonAnchor from "shared/components/buttons/ButtonAnchor";
import { ICONS } from "shared/components/Icon";
import { userLogin } from "shared/helpers/userLogin";
import { snackbarError } from "shared/redux/actions/Snackbar";

const AuthLogin = ({ email, setRegistration, setPasswordReset, setEmail }) => {

    const { register, handleSubmit, errors, watch } = useForm();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = async (formData) => {
        try {
            const { email, password } = formData;
            await userLogin(email, password);
        } catch (error) {
            dispatch(snackbarError(t("login.error.message")));
        }
    };

    const passwordReset = () => {
        const { email } = watch();
        setEmail(email);
        setPasswordReset(true);
    };

    const onChange = (event) => {
        setEmail(event.target.value);
    };

    const renderLoginForm = () => {
        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h4>{t("common.login")}</h4>
                    <div className="spacer" />
                    <InputText
                        label={t("user.email.label")}
                        name="email"
                        onChange={onChange}
                        defaultValue={email}
                        error={errors.email}
                        inputRef={register({ required: true })}
                    />
                    <InputText
                        label={t("common.password.singular")}
                        name="password"
                        error={errors.password}
                        type="password"
                        inputRef={register({ required: true })}
                    />
                    <input
                        type="submit"
                        className="button is-success is-fullwidth"
                        value={t("common.login")}
                    />
                    <div className={"password-reset-link"}
                        onClick={() => passwordReset()}>
                        {t("common.password.reset")}
                    </div>
                    <div className="spacer-double" />
                </form>
                <h4>{t("authentication.invite.noAccountYet")}</h4>
                <Button
                    label={t("authentication.invite.register")}
                    outlined
                    fullWidth
                    onClick={() => setRegistration(true)}
                />
                <div className="is-divider" />
                <ButtonAnchor
                    label={t("authentication.help.login.label")}
                    icon={ICONS.WARNING}
                    onClick={() => window.open(t("authentication.help.login.link"), "_blank")}
                />
            </>
        );
    };


    return (
        <div className="container-card-centered">
            {renderLoginForm()}
        </div>
    );

};

AuthLogin.propTypes = {
    setRegistration: PropTypes.func.isRequired,
    setPasswordReset: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    email: PropTypes.string
};

export default AuthLogin;
