import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_FINISHED } from "redux/types";
import createErrorMessage from "helpers/createErrorMessage";
import { snackbarError } from "shared/redux/actions/Snackbar";
import { REVIEW_LOADING } from "../../types";

const disapproveBookInspectionReview = loader("graphql/mutations/disapproveBookInspectionReview.graphql");

export default function reviewNegativeResolution(
    inspectionId,
    assessmentRemark,
    hasRecoveryPeriod,
    recoveryPeriod,
) {
    return async (dispatch) => {
        try {

            dispatch({ type: REVIEW_LOADING });

            const { data } = await client.mutate({
                mutation: disapproveBookInspectionReview,
                variables: {
                    inspectionId,
                    assessmentRemark,
                    recoveryPeriod: hasRecoveryPeriod ?
                        recoveryPeriod :
                        undefined
                }
            });

            const resolution = data.disapproveBookInspectionReview;

            dispatch({
                type: REVIEW_FINISHED,
                inspectionId,
                positive: false,
                recoveryPeriod,
                resolution,
            });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "review.finish.failed")));
        }

    };
}
