import { ROLE_LEVELS } from "./roles";

export const MENU_ITEMS = [
    {
        label: "audit.menu.inspections",
        path: "/inspections",
        iconName: "book",
        roleLevel: ROLE_LEVELS.MANAGER
    },
    {
        label: "audit.menu.schedule",
        path: "/schedule",
        iconName: "edit",
        roleLevel: ROLE_LEVELS.MANAGER,
    },
    {
        label: "audit.menu.archived",
        path: "/archived",
        iconName: "folder",
        roleLevel: ROLE_LEVELS.MANAGER
    },
];