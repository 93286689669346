import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Button from "shared/components/buttons/Button";
import ButtonAdd from "shared/components/buttons/ButtonAdd";
import InputText from "shared/components/inputs/InputText";
import ContactItem from "components/Contacts/ContactItem";

import "./style.scss";

const Contacts = ({ contacts, onChange }) => {

    const { t } = useTranslation();
    const { register, handleSubmit, errors } = useForm();
    const [showInput, setShowInput] = useState(false);
    const [edit, setEdit] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    const onSubmit = (formData) => {
        if (isEditing) {
            const newContacts = [...contacts];
            newContacts[edit.index] = formData;
            onChange(newContacts);

        } else {
            const newContacts = [...contacts, formData];
            onChange(newContacts);
        }

        close();
    };

    const close = () => {
        setShowInput(false);
        setIsEditing(false);
        setEdit({});
    }

    const onEdit = async (index) => {
        await setShowInput(false);
        await setEdit({ index, ...contacts[index] });
        setIsEditing(true);
        setShowInput(true);
    };

    const onDelete = (index) => {
        const newContacts = [...contacts];
        newContacts.splice(index, 1);
        onChange(newContacts);
        close();
    };

    return (
        <div className="contacts">
            <div className="spacer" />
            {contacts.map((contact, index) =>
                <ContactItem
                    key={index}
                    index={index}
                    contact={contact}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            )}
            <ButtonAdd
                label={t("contact.button")}
                onClick={setShowInput}
            />
            {showInput &&
                <div className="container-input">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h4 className="light">
                            {isEditing ?
                                t("audit.contact.edit.title") :
                                t("audit.contact.add.title")}
                        </h4>
                        <div className="spacer" />
                        <div className="cols">
                            <div className="col">
                                <InputText
                                    dark
                                    label={t("contact.name")}
                                    name="name"
                                    error={errors.name}
                                    defaultValue={edit.name}
                                    inputRef={register({ required: true })}
                                />
                            </div>
                            <div className="col">
                                <InputText
                                    dark
                                    label={t("contact.surname")}
                                    name="surname"
                                    error={errors.surname}
                                    defaultValue={edit.surname}
                                    inputRef={register({ required: true })}
                                />
                            </div>
                        </div>
                        <div className="cols">
                            <div className="col">
                                <InputText
                                    dark
                                    label={t("contact.email")}
                                    name="email"
                                    error={errors.email}
                                    defaultValue={edit.email}
                                    inputRef={register({ required: true })}
                                />
                            </div>
                            <div className="col">
                                <InputText
                                    dark
                                    label={t("contact.phoneNumber")}
                                    name="phoneNumber"
                                    error={errors.phoneNumber}
                                    defaultValue={edit.phoneNumber}
                                    inputRef={register()}
                                />
                            </div>
                        </div>
                        <div className="container-buttons">
                            <Button
                                onClick={close}
                                dark
                                outlined
                                label={t("audit.contact.cancel.button")}
                            />
                            <Button
                                type="submit"
                                spacedLeft
                                dark
                                label={isEditing ?
                                    t("audit.contact.edit.button") :
                                    t("audit.contact.add.button")}
                            />
                        </div>
                    </form>
                </div>
            }
        </div>
    );
};

Contacts.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default Contacts;