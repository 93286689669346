import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

const AlertContainer = ({ children, onCancel }) => {

    return (
        <div className="container-overlay">
            <div className="container-card-centered light">
                {onCancel &&
                    <button
                        className="delete is-medium"
                        data-testid="closeAlert"
                        aria-label="close"
                        onClick={onCancel}
                    />
                }
                {children}
            </div>
        </div>
    );
};

AlertContainer.propTypes = {
    children: PropTypes.array,
    onCancel: PropTypes.func,
};

export default AlertContainer;