import React from "react";
import { useTranslation } from "react-i18next";

import MarkDownView from "shared/components/MarkdownView";

// Build the custom sidebar for this first step
function AuthSidebar() {

    const { t } = useTranslation();

    return (
        <div className="sidebar-landing">
            <img
                src="/images/header.png"
                alt="OKCOMPLY"
                className="sidebar-logo" />
            <div className="spacer-double" />
            <p className="modal-card-title">
                {t("authentication.sidebar.title")}
            </p>
            <div className="spacer-double" />
            <p>
                <MarkDownView text={t("authentication.sidebar.content")} />
            </p>
        </div>
    );
};

export default AuthSidebar;
