import { loader } from "graphql.macro";
import { USERS_LOADED } from "../../types";
import client from "graphql/client";

const fetchUsers = loader("shared/graphql/queries/users/fetchUsers.graphql");

export default function usersLoad() {
    return async (dispatch, getState) => {

        const { id: organisationId } = getState().organisation.current;

        const { data } = await client.query({
            query: fetchUsers,
            variables: { organisationId }
        });

        const { users } = data;
        const mappedUsers = {};

        users.map((user)=> {
            mappedUsers[user.id] = user;
        });

        return dispatch({ 
            type: USERS_LOADED, 
            users: mappedUsers 
        });

    };
}