import produce from "immer";

import { USER_LOADED } from "../types";

const initialState = {
    user: {},
    loading: false,
};

const userReducer = produce((draft, action) => {
    switch (action.type) {
        case USER_LOADED:
            draft.user = action.user;
            break;
        default:
            return;
    }
}, initialState);


export default userReducer;