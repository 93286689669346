import React from "react";
import PropTypes from "prop-types";
import { PERIODS } from "../../constants";
import { useTranslation } from "react-i18next";
import InputText from "shared/components/inputs/InputText";
import InputSelect from "shared/components/inputs/InputSelect";

const InputPhase = ({
    defaultValue,
    setTime,
    setTimes,
    labelTimes,
    labelTime
}) => {

    const { t } = useTranslation();

    const periodMapping = PERIODS.map(({ value }) => {
        return {
            value,
            label: t(`period.${value}`)
        };
    });

    return (
        <div className="cols-m">
            <div className="col">
                <InputText
                    type="number"
                    label={labelTimes || t("audit.schedule.times")}
                    onChange={({ target }) => { setTimes(parseInt(target.value)) }}
                    defaultValue={defaultValue?.times || 1}
                />
            </div>
            <div className="col">
                <InputSelect
                    items={periodMapping}
                    label={labelTime || t("audit.schedule.time")}
                    onChange={({ target }) => { setTime(target.value) }}
                    defaultValue={defaultValue?.time || "years"}
                />
            </div>
        </div>
    );
}

InputPhase.propTypes = {
    defaultValue: PropTypes.object,
    setTimes: PropTypes.func.isRequired,
    setTime: PropTypes.func.isRequired,
    labelTime: PropTypes.string,
    labelTimes: PropTypes.string,
};

export default InputPhase;