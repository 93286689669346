import { INSPECTIONS_FILTER } from "redux/types";
import inspectionsLoad from "./inspectionsLoad";

export default function inspectionsFilter(filters) {
    return async (dispatch, getState) => {
        const { filters: oldFilters } = getState().audit;
        // Check if the filters have changed, otherwise ignore
        if (JSON.stringify(oldFilters) !== JSON.stringify(filters)) {
            await dispatch({ type: INSPECTIONS_FILTER, filters });
            dispatch(inspectionsLoad());

        }
    };
}