import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Report from "components/Report";
import { localeDate } from "shared/helpers/date";
import Icon, { ICONS } from "shared/components/Icon";
import FieldSet from "components/FieldSet";
import downloadFile from "shared/helpers/downloadFile";
import ModalReportSend from "components/ModalReportSend";

import "./style.scss";

const HistoryItem = ({ inspection, contacts }) => {

    const [show, setShow] = useState(false);
    const { users } = useSelector(state => state.users);
    const { current: organisation } = useSelector(state => state.organisation);
    const [showResendReport, setShowResendReport] = useState(false);

    const { t } = useTranslation();

    const headerClasses = classNames(
        "history-header",
        show ? "show" : "hide"
    );

    const { book, review, resolution } = inspection;

    if (!review || !resolution) { return null; }

    const { assessedBy, approved, file } = resolution;

    const renderAuditor = () => {
        const { userId } = assessedBy;
        const { name, surname } = users[userId];
        return `${name} ${surname}`;
    };

    return (
        <div className="history-item">
            <div className={headerClasses}>
                <Icon spacedRight name={ICONS.CLIPBOARD} />
                <div className="header-details">
                    <span className="date">{localeDate(review.reviewDate)}</span>
                    <span className="reviewer">
                        {approved ? t("inspection.approvedBy") : t("inspection.disapprovedBy")}
                        {` `}
                        {renderAuditor()}
                    </span>
                </div>
                <Icon
                    spacedLeft
                    name={ICONS.MAIL}
                    onClick={() => setShowResendReport(true)}
                />
                <div className="divider-vertical" />
                <Icon
                    name={ICONS.FILE}
                    onClick={() =>
                        downloadFile({
                            filename: file,
                            fileType: "pdf",
                            originalFilename: "report.pdf"
                        }, organisation.id)
                    }
                />
                <div className="divider-vertical" />
                <Icon
                    name={show ? ICONS.UP : ICONS.DOWN}
                    onClick={() => setShow(!show)}
                />
            </div>
            {show &&
                <div className="finished-content">
                    <div className="cols">
                        <div className="col">
                            <FieldSet
                                label={t("inspection.inspectionDate")}
                                value={localeDate(review.reviewDate)}
                            />
                            <FieldSet
                                label={t("inspection.auditor")}
                                value={renderAuditor()}
                            />
                        </div>
                        <div className="col">
                            <FieldSet
                                label={t("inspection.comments")}
                                value={resolution?.assessmentRemark}
                            />
                        </div>
                    </div>
                    <Report
                        finished
                        review={review}
                        book={book}
                    />
                </div>
            }
            {showResendReport &&
                <ModalReportSend
                    inspection={{
                        ...inspection,
                        contacts,
                        resolution: {
                            ...inspection.resolution,
                            file: {
                                filename: file,
                                fileType: "pdf",
                                originalFilename: "report.pdf"

                            }
                        }
                    }}
                    onClose={() => setShowResendReport(false)}
                />
            }
        </div>
    );
};

HistoryItem.propTypes = {
    inspection: PropTypes.object.isRequired,
    contacts: PropTypes.array.isRequired
};

export default HistoryItem;
