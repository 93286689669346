import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { REVIEW_CHAPTER_RATED } from "redux/types";
import createErrorMessage from "helpers/createErrorMessage";
import { snackbarError } from "shared/redux/actions/Snackbar";

const storeReviewRatingMutation = loader("graphql/mutations/storeReviewRating.graphql");

export default function reviewRateChapter(
    inspectionId,
    chapterId,
    positive,
    comment
) {
    return async (dispatch) => {

        try {
            
            const rating = positive ? "POSITIVE" : "NEGATIVE";

            const { data } = await client.mutate({
                mutation: storeReviewRatingMutation,
                variables: {
                    inspectionId,
                    reviewRatingInput: {
                        chapter: chapterId,
                        rating,
                        comment
                    }
                }
            });

            dispatch({
                type: REVIEW_CHAPTER_RATED,
                chapterId,
                rating : data.storeReviewRating,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "chapter.rate.failed")));
        }

    };
}
