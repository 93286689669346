import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import organisationSet from "./organisationSet";
import createErrorMessage from "helpers/createErrorMessage";
import { snackbarError } from "shared/redux/actions/Snackbar";
import { ORGANISATIONS_LOADED } from "../../types";

const fetchOrganisations = loader("../../../shared/graphql/queries/organisation/fetchOrganisations.graphql");

export default function organisationLoadAll() {
    return async (dispatch, getState) => {
        try {
            const currentOrganisation = getState().organisation.current;

            const response = await client.query({ query: fetchOrganisations });

            const { organisations } = response.data;

            await dispatch({
                type: ORGANISATIONS_LOADED,
                organisations
            });

            if (!currentOrganisation) {
                await dispatch(organisationSet(0));
            }

        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "organisations.load.failed")));
        }
    };
}
