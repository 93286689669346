import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useParams } from "react-router";

import Icon from "shared/components/Icon";
import Comment from "components/Comment";
import { localeDate } from "helpers/formattedDate";

import "./style.scss";

const Question = ({
    question,
    answer,
    review,
    onHasContent
}) => {

    const { comments } = review || {};
    const { id: chapterId } = useParams();

    if (!question) return null;

    const createAnswer = () => {

        switch (question.type) {
            case "trueOrFalse":
            case "selectOption": {
                const result = question.answers.find((option) => {
                    return option.value === answer.answer;
                });
                return {
                    answer: result.label,
                    indicator: result.auditIndicator
                };
            }
            case "openQuestion": {
                return {
                    answer: answer.answer,
                    indicator: question.auditIndicator
                };
            }
            case "dateInput":
                return {
                    answer: localeDate(answer.answer),
                    indicator: question.auditIndicator
                };
            default:
                return null;
        }
    };

    const answerInput = createAnswer();
    if (!answerInput) return null;

    const { indicator } = answerInput;
    const answerClasses = classNames(
        "answer",
        indicator
    );

    const indicatorIcons = {
        "GREEN": "check",
        "ORANGE": "exclamation-triangle",
        "RED": "times",
    };
    const commentKeys = Object.keys(comments);

    if (chapterId === "overview"
        && (indicator === "GREEN" || indicator === "ORANGE")
        && commentKeys.length === 0
    ) {
        return null;
    } else {
        onHasContent && onHasContent();
    }

    return (
        <div className="report-answer-set">
            <div className="answer-set-inner">
                <div className="content">
                    <span className="question">{question.label}</span>
                    <span className={answerClasses}>
                        <Icon spacedRight name={indicatorIcons[indicator]} />
                        <span>{answerInput.answer}</span>
                    </span>
                </div>
            </div>
            <div className="comments">
                {commentKeys.map((key) =>
                    <Comment
                        key={key}
                        comment={comments[key]}
                    />
                )}
            </div>
        </div>
    );
};

Question.propTypes = {
    answer: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
    review: PropTypes.object.isRequired,
    onHasContent: PropTypes.func,
};

export default Question;