import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { INSPECTION_HISTORY_LOADED } from "redux/types";

const historyBookInspections = loader("graphql/queries/historyBookInspections.graphql");

export default function inspectionHistoryLoad(subscriptionId) {
    return async (dispatch, getState) => {
        try {
            // Use the current organisationId to fetch the inspection history
            const { id: auditOrganisationId } = getState().organisation.current;

            const response = await client.query({
                query: historyBookInspections,
                variables: {
                    auditOrganisationId,
                    subscriptionId
                }
            });

            const { historyBookInspections: bookInspections } = response.data;
            const inspections = [];

            bookInspections.forEach((inspection) => {
                const { review, book, resolution } = inspection;
                const { chapters } = book;

                inspections.push({
                    ...inspection,
                    review: review ? {
                        ...review,
                        progressState: {
                            ...review.progressState,
                            chapters: JSON.parse(review.progressState.chapters)
                        },
                        reviewData: JSON.parse(review.reviewData)
                    } : null,
                    resolution: JSON.parse(resolution),
                    book: {
                        ...book,
                        chapters: JSON.parse(chapters)
                    },
                    // Fun stuff because we are using graphql \o/
                    notes: inspection.notes
                        .map(({ timestamp, userId, text }) => {
                            return { timestamp, userId, text };
                        }),
                    contacts: inspection.contacts
                        .map(({ name, surname, email, phoneNumber }) => {
                            return { name, surname, email, phoneNumber };
                        }),
                });
            });

            inspections.sort((b, a) => {
                return new Date(a.completeDate).getTime()
                     - new Date(b.completeDate).getTime();
            });

            dispatch({
                type: INSPECTION_HISTORY_LOADED,
                inspections: inspections
            });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "inspections.load.failed")));
        }
    };
}