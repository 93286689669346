import React from "react";

import "./style.scss";

const Loading = () => (
    <div className="loading-container">
        <div className="loading-animation">Loading...</div>
    </div>
);

export default Loading;
