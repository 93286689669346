import React from "react";
import Icon from "shared/components/Icon";
import { ICONS } from "shared/components/Icon";
import PropTypes from "prop-types";
import "./style.scss";
import classNames from "classnames";

const RatingButtons = ({ onApprove, onDisapprove, rating }) => {

    const approveClasses = classNames(
        "button-rating",
        "approve",
        rating === 1 ? "approved" : "",
    );

    const disapproveClasses = classNames(
        "button-rating",
        "disapprove",
        rating === -1 ? "disapproved" : "",
    );

    return (
        <div className="rating-buttons">
            <div onClick={onApprove} className={approveClasses} >
                <Icon name={ICONS.CHECK} />
            </div>
            <div onClick={onDisapprove} className={disapproveClasses} >
                <Icon name={ICONS.CROSS} />
            </div>
        </div>
    );
};

RatingButtons.propTypes = {
    onApprove: PropTypes.func.isRequired,
    onDisapprove: PropTypes.func.isRequired,
    rating: PropTypes.string
};

export default RatingButtons;