
import { HttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ENVIRONMENT_URL } from "constants/environment";
import Cookies from "universal-cookie";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        // eslint-disable-next-line id-match
        __schema: {
            types: [
                {
                    kind: "UNION",
                    name: "MeasureAttributeType",
                    possibleTypes: [
                        {
                            name: "PeriodType",
                        },
                        {
                            name: "PhaseShiftType"
                        }]
                },
                {
                    kind: "UNION",
                    name: "TaskableType",
                    possibleTypes: [
                        {
                            name: "DossierType",
                        },
                        {
                            name: "ReportType"
                        }]
                },
                {
                    kind: "UNION",
                    name: "AttributeType",
                    possibleTypes: [
                        {
                            name: "AttributeAssetType"
                        },
                        {
                            name: "AttributeGroupType"
                        },
                        {
                            name: "AttributePersonType"
                        },
                        {
                            name: "AttributeStructureType"
                        },
                        {
                            name: "FileType"
                        }
                    ]
                }
            ]
        }
    }
});


const cookies = new Cookies();
const token = cookies.get("id_token");

const client = new ApolloClient({
    link: new HttpLink({
        uri: `${ENVIRONMENT_URL}/graphql`,
        headers: {
            authorization: `Bearer ${token}`,
        },
    }),
    cache: new InMemoryCache({ fragmentMatcher }),
});


export default client;