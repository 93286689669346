import produce from "immer";
import {
    AUDIT_SAVE_INSPECTION_ITEMS,
    INSPECTION_UPDATED,
    INSPECTIONS_FILTER,
    INSPECTIONS_FILTER_REMOVE,
    INSPECTIONS_LOADING,
    INSPECTIONS_ARCHIVED_LOADED,
    INSPECTION_ARCHIVED,
    INSPECTION_REOPENED,
    REVIEW_STOPPED,
    REVIEW_STARTED,
    REVIEW_FINISHED,
    INSPECTIONS_FILTER_RESET_DATE
} from "../types";

const initialState = {
    loading: true,
    inspections: {},
    archived: {},
    headerItems: [],
    filters: {
        dateStart: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
        dateEnd: new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0, 0)
    }
};

const auditReducer = produce((draft, action) => {
    switch (action.type) {
        case AUDIT_SAVE_INSPECTION_ITEMS: {
            const { inspections } = action;
            draft.loading = false;
            draft.inspections = inspections;
            break;
        }
        case INSPECTION_ARCHIVED: {
            const { inspectionId } = action;
            const inspection = draft.inspections[inspectionId];
            inspection.archived = true;
            delete draft.inspections[inspectionId];
            draft.archived[inspectionId] = inspection;
            break;
        }
        case INSPECTION_REOPENED: {
            const { inspectionId } = action;
            const inspection = draft.archived[inspectionId];
            inspection.archived = undefined;
            delete draft.archived[inspectionId];
            draft.inspections[inspectionId] = inspection;
            break;
        }
        case INSPECTIONS_ARCHIVED_LOADED: {
            draft.loading = false;
            draft.archived = action.inspections;
            break;
        }
        case INSPECTIONS_LOADING: {
            draft.loading = true;
            break;
        }
        case INSPECTION_UPDATED: {
            const { inspection } = action;
            draft.loading = false;
            draft.inspections[inspection.id] = {
                ...draft.inspections[inspection.id],
                ...inspection,
                // Fun stuff because we are using graphql \o/
                notes: inspection.notes
                    .map(({ timestamp, userId, text }) => {
                        return { timestamp, userId, text };
                    }),
                contacts: inspection.contacts
                    .map(({ name, surname, email, phoneNumber }) => {
                        return { name, surname, email, phoneNumber };
                    }),
            };
            break;
        }
        case REVIEW_STARTED: {
            const { inspectionId, review } = action;
            draft.inspections[inspectionId].review = review;
            break;
        }
        case REVIEW_FINISHED: {
            const { inspectionId, resolution, recoveryPeriod } = action;
            draft.inspections[inspectionId].resolution = resolution;
            draft.inspections[inspectionId].recoveryPeriod = recoveryPeriod || null;
            break;
        }
        case REVIEW_STOPPED: {
            const { inspectionId, review } = action;
            draft.inspections[inspectionId].review = review;
            break;
        }
        case INSPECTIONS_FILTER: {
            draft.filters = action.filters;
            break;
        }
        case INSPECTIONS_FILTER_REMOVE: {
            draft.filters[action.filterName] = null;
            break;
        }
        case INSPECTIONS_FILTER_RESET_DATE: {
            draft.filters.dateStart = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
            draft.filters.dateEnd = new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0, 0);
            break;
        }
        default:
            return;
    }
}, initialState);


export default auditReducer;