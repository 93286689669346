import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { INSPECTION_REPORT_EMAIL_SENT } from "redux/types";

const sendInspectionReportEmail = loader("graphql/queries/sendInspectionReportEmail.graphql");

export default function inspectionSendReportEmail(inspectionId, email, name) {
    return async (dispatch, getState) => {
        try {
            const { id: auditOrganisationId } = getState().organisation.current;
            
            const { data } = await client.query({
                query: sendInspectionReportEmail,
                variables: {
                    inspectionId,
                    auditOrganisationId,
                    email,
                    name
                }
            });

            dispatch(snackbarSuccess("audit.inspection.email.sent"));
            dispatch({
                type: INSPECTION_REPORT_EMAIL_SENT,
                inspection: data.editBookInspection
            });
            
        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error)));
        }
    };
}
