import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { REVIEW_LOADED } from "redux/types";

const inspectionCurrentReview = loader("graphql/queries/inspectionCurrentReview.graphql");

export default function reviewLoadCurrent(inspectionId) {

    return async (dispatch, getState) => {
        try {

            // Use the current organisation to fetch active inspections
            const { id: organisationId } = getState().organisation.current;

            const response = await client.query({
                query: inspectionCurrentReview,
                fetchPolicy: "network-only",
                variables: {
                    auditOrganisationId: organisationId,
                    inspectionId
                }
            });

            const { bookInspectionCurrentReview: review } = response.data;

            const inflatedReview = {
                ...review,
                progressState: {
                    ...review.progressState,
                    chapters: JSON.parse(review.progressState.chapters),
                    preFlow: JSON.parse(review.progressState.preFlow)
                },
            };

            if (inflatedReview.reviewData) {
                inflatedReview.reviewData = JSON.parse(review.reviewData);
            } else {
                // We need an empty object, because the book structure should exist
                // even though it's empty
                inflatedReview.reviewData = {
                    chapters: {}
                };
            }

            dispatch({ type: REVIEW_LOADED, review: inflatedReview });


        } catch (error) {
            dispatch(snackbarError(createErrorMessage(error, "review.current.load.failed")));
            console.error(error);
        }
    };

}