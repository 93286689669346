import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { localeDate } from "helpers/formattedDate";
import inspectionStatus from "helpers/inspectionStatus";

import "./style.scss";
import nextAuditDate from "helpers/nextAuditDate";

export default function InspectionItem({ index, item, archived }) {

    const history = useHistory();
    const { t } = useTranslation();
    const {
        organisation,
        node,
        book,
        lastInspectionDate,
        inspectionDeadline,
        recoveryPeriod,
        id,
    } = item;

    let nextAudit = nextAuditDate(item);

    const openInspection = async () => {
        history.push(`/${archived ? "archived" : "inspections"}/${id}/info`);
    };

    const city = node.attributes && node.attributes.length > 0 ? node.attributes[0].city || "" : "";

    const renderAddress = () => {
        if (!node.attributes || node.attributes.length === 0) {
            return;
        }
        const { address, city, postcode, region } = node.attributes[0] || {};
        return (
            <>
                <span>{address}</span>
                <br />
                <span>{postcode}</span>
                <br />
                <span>{city}</span>
                <br />
                <span>{region}</span>
            </>
        );
    };


    const renderStatus = () => {

        const status = inspectionStatus(item);
        const statusClasses = classNames("status", status)

        return (
            <div className={statusClasses} >
                <span className="status-tooltip">
                    {t(`inspection.status.${status}`)}
                </span>
            </div>
        );
    };


    const renderLocation = () => {
        return (
            <div className="image-location">
                <div className="location-circle">
                    <img className="img-location has-pointer" />
                    <span className="tooltip-desktop">
                        {renderAddress()}
                    </span>
                </div>
            </div>);
    };

    const renderLastInspectionDate = () => {
        return lastInspectionDate ? localeDate(lastInspectionDate) : "-";
    };

    const renderNextInspectionDate = () => {
        if (nextAudit) return localeDate(nextAudit);
        return inspectionDeadline ? localeDate(inspectionDeadline) : "-";
    };

    const renderRecoveryPeriod = () => {
        return recoveryPeriod ? `${recoveryPeriod.times} ${t(`period.${recoveryPeriod.time}`)}` : "-";
    };

    const dataTestId = `inspectionListItem${index}`;

    return (
        <div
            data-testid={dataTestId}
            className="inspection-list-item"
            onClick={openInspection}
        >
            <div className="inspection-list-item-rows">
                <div className="inspection-list-item-row wide">
                    <div className="inspection-list-item-column">
                        <div className="item is-bold is-center ">
                            {organisation.label}
                        </div>
                        <div className="container-status hidden-desktop">
                            {renderStatus()}
                        </div>
                    </div>
                </div>
                <div className="inspection-list-item-row">
                    <div className="inspection-list-item-column">
                        <div className="label">
                            Location
                        </div>
                        <div className="item is-row">
                            {renderLocation()}
                            <div className="is-column">
                                <span>{node.label}</span>
                                <p>{city}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inspection-list-item-row">
                    <div className="inspection-list-item-column">
                        <div className="label">
                            Boek
                        </div>
                        <div className="item">
                            {book.label}
                        </div>
                    </div>
                </div>
                <div className="inspection-list-item-row">
                    <div className="inspection-list-item-column">
                        <div className="label">
                            Laatste audit
                        </div>
                        <div className="item">
                            {renderLastInspectionDate()}
                        </div>
                    </div>
                </div>
                {!archived &&
                    <>
                        <div className="inspection-list-item-row">
                            <div className="inspection-list-item-column">
                                <div className="label">
                                    Recovery period
                                </div>
                                <div className="item">
                                    {renderRecoveryPeriod()}
                                </div>
                            </div>
                        </div>
                        <div className="inspection-list-item-row">
                            <div className="inspection-list-item-column">
                                <div className="label">
                                    Volgende audit
                                </div>
                                <div className="item">
                                    {renderNextInspectionDate()}
                                </div>
                            </div>
                        </div>
                        <div className="inspection-list-item-row at-end thin">
                            <div className="container-status hidden-tablet-down">
                                {renderStatus()}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>);

}

InspectionItem.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    archived: PropTypes.bool,
};

