import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";

import { localeDate, localeTime } from "helpers/formattedDate";
import DotMenu from "shared/components/DotMenu";

import "./style.scss";

const Comment = ({
    comment,
    index,
    onEdit,
    onDelete,
    canEdit,
    onComplete
}) => {

    const { t } = useTranslation();
    const { users } = useSelector(state => state.users);

    const { comment: text, timestamp, userId } = comment;
    const user = users[userId];
    const name = user ? `${user.name}  ${user.surname}` : "";
    const dateAndTime = `${localeDate(timestamp)} ${t("at")} ${localeTime(timestamp)}`;

    const commentClass = canEdit ? "comment-editable" : "comment-static";

    return (
        <div key={index} className={commentClass}>
            <div className="note-row">
                <div className="user">
                    {onComplete &&
                        <Checkbox
                            onChange={onComplete}
                            name="complete"
                            color="primary"
                            size="small"
                        />
                    }
                    <span className="name">{name}</span>
                    <span>{dateAndTime}</span>
                </div>
                <div className="comment">{text || comment.text}</div>
            </div>
            {canEdit &&
                <DotMenu menuItems={[
                    { label: t("audit.comment.edit"), action: () => onEdit(index) },
                    { label: t("audit.comment.delete"), action: () => onDelete(index) },
                ]}
                />
            }
        </div>
    );
};

Comment.propTypes = {
    comment: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onComplete: PropTypes.func,
    canEdit: PropTypes.bool
};


export default Comment;