import auth0 from "auth0-js";
import { snackbarError } from "shared/redux/actions/Snackbar";
import getLoginOptions from "./getLoginOptions";
import isDevelopment from "./isDevelopment";
import isTest from "./isTest";

export function userLogin(email, password) {
    return new Promise((resolve, reject)=> {
        const options = getLoginOptions();
        if (!(isTest() || isDevelopment())) {
            options["configurationBaseUrl"] = "https://login.okcomply.com";
        }
    
        const webAuth = new auth0.WebAuth(options);
    
        webAuth.login({
            username: email,
            password,
            onRedirecting: (done) => {
                done();
                resolve();
            }
        }, (error) => {
            reject(error);
        });
    });
  

}