import { loader } from "graphql.macro";
import client from "../../graphql/client";


const fetchFile = loader("shared/graphql/queries/general/fetchFile.graphql");

export default async function downloadFile(item, organisationId, view) {
    const { filename, latest, fileType } = item;
    
    return client.query({
        query: fetchFile,
        variables: {
            organisationId,
            filename,
            version: latest?.version || undefined
        },
        fetchPolicy: "network-only"
    }).then(({ data }) => {
        
        const { file } = data;
        const { eTag, originalFilename, signedUrl } = file;

        const headers = new Headers();
        headers.append("if-match", eTag);
        headers.append("response-content-type", fileType);
        headers.append("Host", "dev-test-demo-okapi-files.s3.eu-central-1.amazonaws.com");
        headers.append("Access-Control-Request-Method", "GET");

        const request = new Request(signedUrl, {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers
        });

        fetch(request)
            .then(async (response) => await response.blob())
            .then((blob) => {

                const newBlob = new Blob([blob], { type: fileType });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }
                // The only browser proof way to succesfully start a download
                // from within React without using additional modules
                // we have to create a new DOM element which we will virtually click
                const fileURL = window.URL.createObjectURL(newBlob);
                if (view) {
                    window.open(fileURL);
                } else {
                    const link = document.createElement("a");
                    link.href = fileURL;
                    link.download = originalFilename;
                    document.body.appendChild(link);
                    link.click();
                    // remove the link aftwards to clear everything up
                    document.body.removeChild(link);
                }
            })
            .catch((response) => {
            });
    });
};