

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "../../Icon";

import "./style.scss";

const Button = ({
    label,
    onClick,
    outlined,
    secondary,
    disabled,
    dark,
    danger,
    iconLeft,
    iconRight,
    spacedLeft,
    spacedRight,
    fullWidth,
    testId,
    type,
}) => {

    const classes = classnames(
        "button",
        outlined ? "is-outlined" : 
        danger ? "danger" : 
        secondary ? "secondary" : "is-success",
        dark ? "dark" : "",
        spacedLeft ? "spaced-left" : "",
        spacedRight ? "spaced-right" : "",
        fullWidth ? "full-width" : "",
        disabled ? "disabled": "",
    );
    
    return (
        <button
            className={classes}
            data-testid={testId}
            onClick={onClick}
            type={type}
        >
            {iconLeft && <Icon spacedRight={!!label} name={iconLeft} />}
            {label &&<span>{label}</span>}
            {iconRight && <Icon spacedLeft name={iconRight} />}
        </button>
    );
};

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    outlined: PropTypes.bool,
    danger: PropTypes.bool,
    disabled: PropTypes.bool,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    testId: PropTypes.string,
    spacedLeft: PropTypes.bool,
    spacedRight: PropTypes.bool,
    fullWidth: PropTypes.bool,
    secondary: PropTypes.bool,
    dark: PropTypes.bool,
};

export default Button;
