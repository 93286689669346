import React from "react";
import PropTypes from "prop-types";
import Button from "../buttons/Button";
import { useTranslation } from "react-i18next";
import AlertContainer from "../AlertContainer";

import "./style.scss";

const OkWarning = ({
    title,
    message,
    image,
    onConfirm,
    onCancel
}) => {

    const [t] = useTranslation();

    return (
        <div className="ok-warning">
            <AlertContainer>
                <img alt="question" src={image || require("../../assets/img/okki-question.png")} />
                <h3>{title}</h3>
                <div className="spacer" />
                <p>{message}</p>
                <div className="spacer-double" />
                <div className="actions cols">
                    {onCancel &&
                        <div className="col">
                            <Button
                                outlined
                                testId="button-cancel"
                                fullWidth
                                onClick={onCancel}
                                label={t("button.cancel.label")}
                            />
                        </div>}
                    {onConfirm && <div className="col">
                        <Button
                            fullWidth
                            secondary
                            testId="button-confirm"
                            onClick={onConfirm}
                            label={t("button.confirm.label")}
                        />
                    </div>}
                </div>
            </AlertContainer>
        </div>
    );
};

OkWarning.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    image: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    labelConfirm: PropTypes.string,
    labelCancel: PropTypes.string,
};

export default OkWarning;