import client from "../../../graphql/client";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { INSPECTIONS_LOADING, INSPECTIONS_ARCHIVED_LOADED } from "redux/types";

const archivedBookInspections = loader("graphql/queries/archivedBookInspections.graphql");

export default function inspectionsArchivedLoad() {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: INSPECTIONS_LOADING });

            // Use the current organisation to fetch active inspections
            const organisation = getState().organisation.current;
            // const filters = getState().audit.filters;

            const response = await client.query({
                query: archivedBookInspections,
                fetchPolicy: "network-only",
                variables: {
                    auditOrganisationId: organisation.id,
                    // ...filters
                }
            });

            const { archivedBookInspections: archived } = response.data;
            const inspections = {};

            archived.forEach((inspection) => {
                const { review, book, resolution } = inspection;
                const { chapters } = book;

                inspections[inspection.id] = {
                    ...inspection,
                    review: review ? {
                        ...review,
                        progressState: {
                            ...review.progressState,
                            chapters: JSON.parse(review.progressState.chapters)
                        },
                    } : null,
                    resolution: JSON.parse(resolution),
                    book: {
                        ...book,
                        chapters: JSON.parse(chapters)
                    },
                    // Fun stuff because we are using graphql \o/
                    notes: inspection.notes
                        .map(({ timestamp, userId, text }) => {
                            return { timestamp, userId, text };
                        }),
                    contacts: inspection.contacts
                        .map(({ name, surname, email, phoneNumber }) => {
                            return { name, surname, email, phoneNumber };
                        }),
                };
            });

            dispatch({ type: INSPECTIONS_ARCHIVED_LOADED, inspections });

        } catch (error) {
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "inspections.load.failed")));
        }
    };
}
