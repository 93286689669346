import produce from "immer";

import {
    INSPECTION_HISTORY_LOADED
} from "../types";

const initialState = {
    inspections: [],
    loading: true,
};

const inspectionHistoryReducer = produce((draft, action) => {
    switch (action.type) {
        case INSPECTION_HISTORY_LOADED:
            draft.inspections = action.inspections;
            draft.loading = false;
            break;
        default:
            break;
    }
}, initialState);

export default inspectionHistoryReducer;