import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import reviewRateChapter from "redux/actions/review/reviewRateChapter";
import Button from "shared/components/buttons/Button";
import InputText from "shared/components/inputs/InputText";

import "./style.scss";

const ModalRating = ({
    label,
    approved,
    onClose,
    defaultValue
}) => {

    const { id: chapterId, inspectionId } = useParams();
    const [comment, setComment] = useState(defaultValue);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onConfirm = () => {
        dispatch(reviewRateChapter(
            inspectionId,
            chapterId,
            approved,
            comment
        ));

        onClose();
    };

    return (
        <div className="rating-modal-container">
            <div onClick={onClose} className="modal-background" />
            <div className="rating-modal">
                <header className="modal-head">
                    <h3 className="modal-title">
                        {label}
                    </h3>
                    <button
                        className="delete"
                        aria-label="close"
                        onClick={onClose}
                    />
                </header>
                <section className="modal-body">
                    <h4>{approved ? t("chapter.approve.title") : t("chapter.disapprove.title")}</h4>
                    <div className="spacer" />
                    <InputText
                        multiline
                        defaultValue={comment}
                        label={t("chapter.rate.comment")}
                        onChange={({ target }) => { setComment(target.value) }}
                    />
                    <div className="spacer" />
                    <div className="container-buttons">
                        <Button
                            outlined
                            onClick={onClose}
                            label={t("button.cancel.label")}
                        />
                        <Button
                            spacedLeft
                            danger={!approved}
                            onClick={onConfirm}
                            label={approved ?
                                t("chapter.approve.button") :
                                t("chapter.disapprove.button")
                            }
                        />
                    </div>
                </section>
            </div>
        </div>
    );
};

ModalRating.propTypes = {
    label: PropTypes.string.isRequired,
    approved: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
};

export default ModalRating;