

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon  from "../../Icon";

import "./style.scss";

const ButtonAnchor = ({ 
    label, 
    onClick, 
    icon, 
    testId, 
    spacedLeft, 
    spacedRight,
    secondary
}) => {
    const buttonClasses = classNames(
        "button-anchor",
        spacedLeft && "spaced-left",
        spacedRight && "spaced-right",
        secondary && "secondary"
    );
    return (
        <span
            data-testid={testId}
            onClick={onClick}
            className={buttonClasses}>
            {icon &&<Icon spacedRight name={icon} />}
            <span>{label}</span>
        </span>
    );
};

ButtonAnchor.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    testId: PropTypes.string,
    spacedLeft: PropTypes.bool,
    secondary: PropTypes.bool,
    spacedRight: PropTypes.bool,
};

export default ButtonAnchor;
