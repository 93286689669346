import React from "react";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import { Controller } from "react-hook-form";

import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
    Select,
    InputLabel,
    FormControl,
    MenuItem
} from "@material-ui/core";

import "./style.scss";

export default function InputSelect({
    label,
    name,
    onChange,
    error,
    defaultValue,
    items,
    value,
    displayEmpty,
    dark,
    renderValue,
    testId,
    control,
    rules,
    inputRef,
    inputId,
    onClose,
    disabled
}) {

    if (inputRef) {
        console.warn("Please replace inputRef with control");
    }
    const [t] = useTranslation();

    let errorMessage = "";
    if (error) {
        errorMessage = t("formValidation.required");
    }

    const classes = classNames(
        "input-select",
        dark ? "dark" : ""
    );

    const mapMenuItems = () => {
        return items.map(({ label, indicator, value }, key) => {
            return (
                <MenuItem
                    key={key} value={value}
                    id={`${testId}menuItem${key}`}
                    data-testid={`${testId}menuItem${key}`}
                >   
                    {indicator && indicator()}
                    {label}
                </MenuItem>
            );
        });
    };

    const renderField = () => {
        return (
            <Select
                disabled={disabled}
                value={value}
                displayEmpty={displayEmpty}
                renderValue={renderValue}
                variant="outlined"
                defaultValue={defaultValue}
                onChange={onChange}
                error={!!error}
                label={label}
                name={name}
                id={inputId}
                inputProps={{ "data-testid": testId }}
                MenuProps={{
                    disableScrollLock: true,
                }}
                onClose={onClose}
            >
                {mapMenuItems()}
            </Select>
        );
    };

    return (
        !items ? null : // Fixes rendering issue
            <>
                <FormControl
                    className={classes}
                    name={name}
                    variant="outlined"
                    error={error}
                >
                    <InputLabel>{label}</InputLabel>
                    {control ?
                        // Render controlled field
                        <Controller
                            as={renderField()}
                            rules={rules}
                            name={name}
                            control={control}
                            defaultValue={defaultValue}
                        /> :
                        // Render uncontrolled field
                        renderField()
                    }
                    {errorMessage &&
                        <FormHelperText error={error}>
                            {errorMessage}
                        </FormHelperText>
                    }
                </FormControl>
            </>
    );
}


InputSelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    items: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    value: PropTypes.string,
    displayEmpty: PropTypes.bool,
    dark: PropTypes.bool,
    renderValue: PropTypes.func,
    testId: PropTypes.string,
    inputRef: PropTypes.object,
    control: PropTypes.object,
    rules: PropTypes.object,
    inputId: PropTypes.string,
    onClose: PropTypes.func,
    disabled: PropTypes.bool

};
