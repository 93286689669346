import client from "../../../graphql/client";
import i18next from "i18next";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import { BOOKS_LOADED } from "redux/types";

const fetchBooks = loader("shared/graphql/queries/books/fetchBooks.graphql");

export function booksLoad() {
    return async (dispatch, getState) => {

        const { current: organisation } = getState().organisation;
        try {
            await client.query({
                query: fetchBooks,
                variables: { organisationId: organisation.id },
                fetchPolicy: "network-only"
            }).then(({ data }) => {
                const { books } = data;
                const mappedBooks = {};
                books.map((book) => { mappedBooks[book.osbn] = book; });
                dispatch({ type: BOOKS_LOADED, books: mappedBooks });
            });

        } catch (error) {
            dispatch(snackbarError(i18next.t("books.loading.failed")));
        }
    };
}