import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "shared/components/Icon";

import "./style.scss";

const ModuleItem = ({
    image,
    icon,
    path,
    label,
    description,
    disabled
}) => {

    const history = useHistory();
    const navigate = () => {
        if (image) {
            window.location = path;
        } else {
            history.push(path);
        }
    };

    const moduleItemClasses = classNames(
        "module-item",
        disabled && "disabled"
    );

    return (
        <div onClick={navigate} className={moduleItemClasses}>
            {image ?
                <img className="module-image" src={image} /> :
                <div className="module-icon">
                    <Icon name={icon} />
                </div>
            }
            <div className="module-content">
                <span className="module-label">{label}</span>
                <span className="module-description">{description}</span>
            </div>
        </div>
    );
};

ModuleItem.propTypes = {
    image: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};


export default ModuleItem;