import { ORGANISATION_LOADED } from "../../types";

export default function organisationSet(index) {
    return async (dispatch, getState) => {
        const { organisations } = getState().organisation;

        await dispatch({
            type: ORGANISATION_LOADED,
            organisation: organisations[index]
        });

    };
} 