import produce from "immer";

import {
    REVIEW_STARTED,
    REVIEW_COMMENT_PLACED,
    REVIEW_CHAPTER_RATED,
    REVIEW_LOADED,
    REVIEW_COMMENT_DELETED,
    REVIEW_COMMENT_EDITED,
    REVIEW_LOADING,
    REVIEW_FINISHED
} from "../types";

const initialState = {
    inspectionId: null,
    reviewData: {},
    loading: true,
    finished: false,
};

const contructChapter = (draft, chapterId) => {
    if (!draft.reviewData.chapters[chapterId]) {
        draft.reviewData.chapters[chapterId] = {
            // TODO: check if we need additional default state stuff
            items: {
            }
        };
    }
};

const constructQuestion = (draft, chapterId, questionId) => {
    if (!draft.reviewData.chapters[chapterId].items[questionId]) {
        draft.reviewData.chapters[chapterId].items[questionId] = {
            // TODO: check if we need additional default state stuff
            comments: []
        };
    }
};

const reviewReducer = produce((draft, action) => {
    switch (action.type) {
        case REVIEW_FINISHED: {
            draft.loading = false;
            draft.finished = true;
            draft.reportFile = action.reportFile;
            break;
        }
        case REVIEW_LOADING: {
            draft.loading = true;
            break;
        }
        case REVIEW_LOADED: {
            const { review } = action;
            draft.reviewData = review.reviewData;
            draft.progressState = review.progressState;
            draft.loading = false;
            break;
        }
        case REVIEW_STARTED: {
            const { review, inspectionId } = action;
            draft.reviewData = review.reviewData;
            draft.inspectionId = inspectionId;
            draft.progressState = review.progressState;
            draft.loading = false;
            draft.finished = false;
            draft.reportFile = undefined;
            break;
        }
        case REVIEW_CHAPTER_RATED: {
            const { rating, chapterId } = action;
            contructChapter(draft, chapterId);
            draft.loading = false;
            draft.reviewData.chapters[chapterId].rating = {
                ...rating
            };
            break;
        }
        case REVIEW_COMMENT_PLACED: {
            const { comment, chapterId, questionId, key } = action;
            contructChapter(draft, chapterId);
            constructQuestion(draft, chapterId, questionId);
            draft.reviewData.chapters[chapterId].items[questionId].comments[key] = comment;
            break;
        }
        case REVIEW_COMMENT_DELETED: {
            const { chapterId, questionId, key } = action;
            delete draft.reviewData.chapters[chapterId].items[questionId].comments[key];
            break;
        }
        case REVIEW_COMMENT_EDITED: {
            const { comment, chapterId, questionId, key } = action;
            draft.reviewData.chapters[chapterId].items[questionId].comments[key] = comment;
            break;
        }
        default:
            return;
    }
}, initialState);

export default reviewReducer;
