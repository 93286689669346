import React from "react";
import { useDispatch, useSelector } from "react-redux";
import inspectionsFilterRemove from "redux/actions/audit/inspectionsFilterRemove";
import inspectionsFilterResetDate from "redux/actions/audit/inspectionsFilterResetDate";
import { localeDate } from "shared/helpers/date";
import renderUserName from "helpers/renderUserName";

const defaultDateStart = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
const defaultDateEnd = new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0, 0);

const SelectedFilters = () => {

    const { filters } = useSelector(state => state.audit);
    const { users } = useSelector(state => state.users);
    const { books } = useSelector(state => state.books);

    const dispatch = useDispatch();

    let renderDateStart = null;
    let dateStart = null;

    const renderFilter = (value, type) => {

        if (value) {
            let renderValue = value;
            switch (type) {
                case "auditor":
                    if (users[value]) {
                        renderValue = renderUserName(users[value]); 
                    }
                    break;
                case "osbn":
                    if (books && books[value]) {
                        renderValue = books[value].label;
                    }
                    break;
                case "dateStart":
                    renderDateStart = localeDate(value);
                    dateStart = new Date(value);
                    return;
                case "dateEnd":
                    if (dateStart.getTime() !== defaultDateStart.getTime() ||
                        new Date(value).getTime() !== defaultDateEnd.getTime()) {
                        renderValue = `${renderDateStart} -  ${localeDate(value)}`
                        break;
                    } else {
                        return null;
                    }
                default:
                    break;
            }

            return (
                <div className="filter-item">
                    <span>{renderValue}  </span>
                    <button
                        className="delete is-small"
                        onClick={() => {
                            type === "dateEnd" ?
                                dispatch(inspectionsFilterResetDate()) :
                                dispatch(inspectionsFilterRemove(type))
                        }}
                    />
                </div>
            )
        } else {
            return null;
        }
    };

    const filterKeys = filters ? Object.keys(filters) : [];

    return (
        <div className="selected-filter">
            {filterKeys.map((key) => {
                return renderFilter(filters[key], key);
            })}
        </div>
    );
}

export default SelectedFilters;