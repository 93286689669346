import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "moment/locale/nl.js";

import { store } from "./redux/setup/setupStore";
import AppRouter from "./AppRouter";
import OkSnackbar from "shared/components/OkSnackbar";

import "./App.scss";

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
            <OkSnackbar />
        </Provider>
    );
}
