import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { localeDate } from "shared/helpers/date";
import reviewStart from "redux/actions/review/reviewStart";
import reviewStop from "redux/actions/review/reviewStop";

import { getRootHistory } from "AppRouter";
import inspectionUpdate from "redux/actions/audit/inspectionUpdate";

import FieldSet from "components/FieldSet";
import Button from "shared/components/buttons/Button";
import Notes from "components/Notes";
import Contacts from "components/Contacts";
import { REVIEW_STATE } from "constants/audit";
import Status from "components/Status";
import OkWarning from "shared/components/OkWarning";
import nextAuditDate from "helpers/nextAuditDate";

const TabInfo = ({ inspection }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [confirmStopReview, setConfirmStopReview] = useState(false);

    const {
        organisation,
        node,
        book,
        inspectionDeadline,
        review,
        id,
        contacts,
        notes,
        archived,
        recoveryPeriod
    } = inspection;

    let nextAudit = nextAuditDate(inspection);
    let hasActiveReview = false;

    if (review) {
        const currentReview = review;
        hasActiveReview =
            currentReview.status === REVIEW_STATE.IN_PROGRESS ||
            currentReview.status === REVIEW_STATE.NEW;

    }

    const renderNextInspectionDate = () => {
        if (nextAudit) return localeDate(nextAudit);
        return inspectionDeadline ? localeDate(inspectionDeadline) : "-";
    };

    const renderRecoveryPeriod = () => {
        return recoveryPeriod ?
            `${recoveryPeriod.times} ${t(`period.${recoveryPeriod.time}`)}` :
            "";
    };

    const contactsChanged = (contacts) => {
        dispatch(inspectionUpdate({
            inspectionId: inspection.id,
            contacts
        }));
    };

    const notesChanged = (notes) => {
        dispatch(inspectionUpdate({
            inspectionId: inspection.id,
            notes
        }));
    };

    const openReview = async () => {
        await dispatch(reviewStart(id, !hasActiveReview));
        getRootHistory().push(`/inspection/${id}/review/${book.startItem}`);
    };

    const stopReview = async () => {
        setConfirmStopReview(false);
        await dispatch(reviewStop(inspection.id));
    };

    const { address, city, postcode, region, label: nodeLabel } = node.attributes[0];

    return (
        <div className="tab-info ">
            <h3>{t("inspection.label")}</h3>
            <div className="spacer" />
            <div className="cols">
                <div className="col">
                    <FieldSet
                        label={t("inspection.organisationName")}
                        value={organisation.label}
                    />
                    <div className="field-set">
                        <label className="label">{t("inspection.address")}</label>
                        {nodeLabel}
                        <br />
                        {address}
                        <br />
                        {postcode}
                        <br />
                        {city}
                        <br />
                        {region}
                        <br />
                    </div>
                    <FieldSet
                        label={t("inspection.bookName")}
                        value={book.label}
                    />
                </div>
                <div className="col">
                    <div className="field-set">
                        <label className="label">{t("inspection.status.label")}</label>
                        <Status inspection={inspection} />
                    </div>
                    <FieldSet
                        label={t("inspection.recoveryPeriod")}
                        value={renderRecoveryPeriod()}
                    />
                    <FieldSet
                        label={t("inspection.nextAudit")}
                        value={renderNextInspectionDate()}
                    />
                </div>
            </div>
            <div className="divider" />
            <h3>{t("inspection.handleReview")}</h3>
            <div className="spacer" />
            <div className="cols">
                <div className="col">
                    <p>
                        {hasActiveReview ?
                            t("inspection.hasActiveReview") :
                            t("inspection.noActiveReview")
                        }
                    </p>
                    <br />
                    {!inspection.progressCompleted &&
                        <div className="container-warning">
                            {t("inspection.progressIncomplete")}
                        </div>
                    }
                </div>
                <div className="col review-actions">
                    <Button
                        disabled={archived}
                        onClick={openReview}
                        secondary={hasActiveReview}
                        label={hasActiveReview ?
                            t("inspection.openReview") :
                            t("inspection.startReview")
                        }
                    />
                    <div className="spacer" />
                    {hasActiveReview &&
                        <Button
                            disabled={archived}
                            onClick={() => setConfirmStopReview(true)}
                            secondary={hasActiveReview}
                            label={t("inspection.stopReview")}
                            outlined
                        />
                    }
                </div>
            </div>
            <div className="divider" />
            <h3>{t("inspection.notes")}</h3>
            <Notes
                notes={notes}
                onChange={notesChanged}
            />
            <div className="divider" />
            <h3>{t("inspection.contacts")}</h3>
            <Contacts
                contacts={contacts}
                onChange={contactsChanged}
            />
            {confirmStopReview &&
                <OkWarning
                    title={t("audit.stopReview.title")}
                    message={t("audit.stopReview.message")}
                    labelConfirm={t("audit.stopReview.confirm")}
                    onConfirm={stopReview}
                    onCancel={() => setConfirmStopReview(false)}
                />
            }
        </div>
    );
};

TabInfo.propTypes = {
    inspection: PropTypes.object
};

export default TabInfo;
