import Report from "components/Report";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import InputText from "shared/components/inputs/InputText";
import InputPhase from "components/InputPhase";
import RadioSelector from "components/RadioSelector";
import ButtonFlow from "components/ButtonFlow";
import reviewPositiveResolution from "redux/actions/review/reviewPositiveResolution";
import reviewNegativeResolution from "redux/actions/review/reviewNegativeResolution";

import { snackbarSuccess } from "shared/redux/actions/Snackbar";
import Loading from "shared/components/Loading";
import Button from "shared/components/buttons/Button";
import OkWarning from "shared/components/OkWarning";
import ModalReportSend from "components/ModalReportSend";

import "./style.scss";

const Resolution = () => {

    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { inspectionId } = useParams();

    const review = useSelector(state => state.review);
    const { inspections } = useSelector(state => state.audit);

    const { progressState, loading, finished } = review;
    const { book } = inspections[inspectionId];

    const [hasRecoveryPeriod, setHasRecoveryPeriod] = useState(true);
    const [time, setTime] = useState("months");
    const [times, setTimes] = useState(1);
    const [comment, setComment] = useState("");
    const [approved, setApproved] = useState(true);
    const [confirmReviewNegative, setConfirmReviewNegative] = useState(false);

    const { chapters: chapterProgress } = progressState;
    const chapterIds = Object.keys(chapterProgress);

    const reviewPositive = async () => {
        if (!comment || comment.length === 0) {
            alert(t("audit.review.commentRequired"));
            return;
        }
        await dispatch(reviewPositiveResolution(inspectionId, comment));
        dispatch(snackbarSuccess("review.finished.positive"));
    };

    const reviewNegative = async () => {
        if (!comment || comment.length === 0) {
            alert(t("audit.review.commentRequired"));
            return;
        }
        setConfirmReviewNegative(false);
        await dispatch(reviewNegativeResolution(
            inspectionId,
            comment,
            hasRecoveryPeriod,
            { time, times }
        ));
        if (hasRecoveryPeriod) {
            dispatch(snackbarSuccess("review.finished.negative"));
        }
    };

    const renderResolutionForm = () => {
        return (
            <>
                <h3>{t("audit.review.finish")}</h3>
                <div className="spacer-double" />
                <RadioSelector
                    firstLabel={t("audit.approve.check")}
                    secondLabel={t("audit.disapprove.check")}
                    selected={approved}
                    onHandleSelected={setApproved}
                />
                <div className="container-form">
                    <InputText
                        label={t("audit.resolution.note")}
                        onChange={({ target }) => setComment(target.value)}
                        error={null}
                        multiline
                        inputRef={""}
                    />
                    {!approved &&
                        <>
                            <FormControlLabel
                                label={t("audit.hasRecoveryPeriod")}
                                control={
                                    <Checkbox
                                        checked={hasRecoveryPeriod}
                                        onChange={({ target }) => setHasRecoveryPeriod(target.checked)}
                                        name="recoveryPeriod"
                                        color="primary"
                                    />
                                }
                            />
                            <div className="spacer" />
                            {hasRecoveryPeriod &&
                                <InputPhase
                                    setTime={setTime}
                                    setTimes={setTimes}
                                    labelTime={t("audit.resolution.time")}
                                    labelTimes={t("audit.resolution.times")}
                                    defaultValue={{
                                        time,
                                        times
                                    }}
                                />
                            }
                        </>
                    }
                    <div className="container-buttons">
                        {approved ?
                            <Button
                                spacedLeft
                                label={t("audit.approve.button")}
                                onClick={reviewPositive}
                            />
                            :
                            <Button
                                danger
                                label={t("audit.disapprove.button")}
                                onClick={() => {
                                    hasRecoveryPeriod ? reviewNegative() :
                                        setConfirmReviewNegative(true);
                                }}
                            />
                        }
                    </div>
                </div>
            </ >
        );
    };

    return (
        <div className="audit-resolution">
            <div className="container-header">
                <ButtonFlow
                    onClick={() => {
                        history.push(`/inspection/${inspectionId}/review/${chapterIds.pop()}`);
                    }}
                />
                <h1>{t("audit.review.overview")}</h1>
                <ButtonFlow
                    isNext
                    disabled
                />
            </div>
            <div className="group">
                <h3>{t("audit.review.title")}</h3>
                <div className="spacer" />
                <Report
                    review={review}
                    book={book}
                />
            </div>
            <div className="group">
                {loading ?
                    <div className="container-loading">
                        <Loading />
                        <div>{t("audit.review.loading")}</div>
                    </div> :
                    finished ?
                        <ModalReportSend
                            inspection={inspections[inspectionId]}
                            onClose={() => history.push("/inspections")}
                        /> :
                        renderResolutionForm()
                }
            </div>
            {confirmReviewNegative &&
                <OkWarning
                    title={t("audit.noRecovery.title")}
                    message={t("audit.noRecovery.message")}
                    labelConfirm={t("audit.noRecovery.confirm")}
                    onConfirm={reviewNegative}
                    onCancel={() => setConfirmReviewNegative(false)}
                />
            }
        </div>
    );
};


export default Resolution;